import React from 'react';
import { Link } from 'react-router-dom';

import { RoutePaths } from '../../../routes/types';
import { Col, Container, Row } from '../../../components/grid';
import { Text } from '../../../components/typography';
import Button from '../../../components/button';
import { ReactComponent as Logo } from '../../../assets/icons/logo-big.svg';
import { ReactComponent as LandingImage } from '../../../assets/images/landing/landing-mobile-img.svg';

import styles from './MobileLanding.module.scss';

const MobileLanding = () => {
  return (
    <Container main>
      <Col className={styles.landing} direction="column">
        <Row className={styles.landing__logo} justify="center">
          <Logo />
        </Row>
        <Row justify="center" className={styles.image}>
          <LandingImage />
        </Row>
        <Col className={styles.landing__description} direction="column" align="center" justify="center">
          <Text size="l" weight="bold">
            Data plans at local rates
          </Text>
          <Text size="l" weight="bold">Without physical SIM-card</Text>
          <Text size="l" weight="bold">Use your eSIM</Text>
        </Col>
        <Col direction="column">
          <Link to={RoutePaths.SignUp}>
            <Row>
              <Button fluid theme="accent">Sign up</Button>
            </Row>
          </Link>
          <Link to={RoutePaths.SignIn}>
            <Row>
              <Button fluid theme="secondary-light">Log in</Button>
            </Row>
          </Link>
        </Col>
      </Col>
    </Container>
  );
};

export default MobileLanding;
