import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { reducer as authReducer } from './reducers/auth';
import { reducer as packagesReducer } from './reducers/packages';
import { reducer as packageReducer } from './reducers/package';

const rootReducer = combineReducers({
  auth: authReducer,
  packages: packagesReducer,
  package: packageReducer,
});

export type StoreType = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
});

export default store;
