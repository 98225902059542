import React from 'react';
import cn from 'classnames';
import ReactMarkdown from 'react-markdown';

import { Col, Row } from '../../grid';
import { Text } from '../../typography';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/drawer-arrow.svg';

import styles from './DrawerItem.module.scss';

export interface DrawerItemProps {
  title: string;
  content: string | JSX.Element;
  isActive?: boolean;
  onClick?: VoidFunction;
}

const DrawerItem = ({
  title, content, isActive, onClick,
}: DrawerItemProps) => {
  return (
    <Row
      onClick={onClick}
      className={cn(styles.item, { [styles.item_active]: isActive })}
      direction="column"
    >
      <Row noGutter className={styles.item__head}>
        <Col grow align="center">
          <Text weight={isActive ? 'medium' : 'thin'}>{title}</Text>
        </Col>
        <Col align="center">
          <ArrowIcon className={styles.item__arrow} />
        </Col>
      </Row>
      <Row noGutter className={styles.item__content}>
        <div className={styles.item__contentWrapper}>
          {typeof content === 'string' ? <ReactMarkdown>{String(content)}</ReactMarkdown> : content}
        </div>
      </Row>
    </Row>
  );
};

export default DrawerItem;
