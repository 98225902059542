import React from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import round from 'lodash/round';

import Loader from '../../../components/loader';
import Card from '../card';
import ChartLegend from './chart-legend';
import Chart from './chart';
import styles from './ChartWidget.module.scss';
import { useChartWidgetData } from './hooks';
import { RouteParams } from '../types';
import { UsageItem } from '../../../store/reducers/package/types';
import { useLoadData } from '../hooks';

const ChartWidget = () => {
  const { packageSlug, period } = useParams<RouteParams>();
  const details = useChartWidgetData({ period });
  const isLoading = useLoadData({ id: packageSlug, period });
  const items: UsageItem[] = [];

  details.forEach((el) => {
    items.push(...el.details);
  });

  const total = details.reduce((acc, obj) => {
    return acc + obj.total;
  }, 0);

  const date = moment().format('MM YYYY');
  const itemDate = moment.unix(Number(period)).format('MM YYYY');
  const title = date === itemDate ? `${moment.unix(Number(period)).format('MMM YYYY')}` : `${moment.unix(Number(period)).format('MMM YYYY')}`;

  return (
    <Card
      className={styles.widget}
      titleRight={<ChartLegend total={round(total * 1024 * 1024, 2)} />}
      title={title}
    >
      <div className={styles.widget__wrapper}>
        {isLoading ? <div className={styles.wrapper}><Loader color="blue" /></div> : <Chart details={items} />}
      </div>
    </Card>
  );
};

export default ChartWidget;
