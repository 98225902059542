import { CircleType } from '../circles';

export const CIRCLES_1: CircleType[] = [{
  color: 'purple',
  x: '11%',
  y: '22%',
  size: 13,
},
{
  color: 'blue',
  x: '13%',
  y: '67%',
  size: 5,
},
{
  color: 'blue',
  x: '87%',
  y: '66%',
  size: 11,
},
{
  color: 'blue',
  x: '80%',
  y: '20%',
  size: 5,
}];

export const CIRCLES_2 = [{
  color: 'orange-grad',
  x: '6%',
  y: '33%',
  size: 13,
},
{
  color: 'orange',
  x: '73%',
  y: '9%',
  size: 7,
},
{
  color: 'orange',
  x: '87%',
  y: '68%',
  size: 11,
}];

export const CIRCLES_3 = [{
  color: 'gray',
  x: '17%',
  y: '28%',
  size: 5,
},
{
  color: 'gray',
  x: '7%',
  y: '63%',
  size: 5,
},
{
  color: 'gray',
  x: '86%',
  y: '66%',
  size: 11,
}];
