import React from 'react';

import Loader from '../../loader';

import styles from './ScreenLoader.module.scss';

const ScreenLoader = () => {
  return (
    <div className={styles.wrapper}>
      <Loader className={styles.wrapper__loader} />
    </div>
  );
};

export default ScreenLoader;
