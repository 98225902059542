import React from 'react';
import cn from 'classnames';

import { Col, Row } from '../../../../components/grid';

import ChartLegendItem from './ChartLegendItem';
import styles from './ChartLegend.module.scss';

interface ChartLegendProps {
   total: number;
}

const ChartLegend = ({ total }: ChartLegendProps) => {
  return (
    <Row className={styles.legend}>
      <Col className={cn(styles.legend__item, styles.legend__item_pad)}>
        <ChartLegendItem volume={total} type="total" />
      </Col>
    </Row>
  );
};

export default ChartLegend;
