import { StoreType } from '../index';

export const getAllPackagesItems = (s: StoreType) => s.packages.all;
export const getPackageById = (packageId: string | null) => (s: StoreType) => {
  const allPackages = getAllPackagesItems(s);
  return allPackages.find(({ id }: any) => id === packageId);
};
export const getPackageBySlug = (searchSlug: string) => (s: StoreType) => {
  const allPackages = getAllPackagesItems(s);
  return allPackages.find(({ slug }: any) => slug === searchSlug);
};

export const getPackageByPlanId = (planId: string | null) => (s: StoreType) => {
  const allPackages = getAllPackagesItems(s);
  return allPackages.find((packageItem: any) => {
    return (packageItem.plans ?? []).some(({ id }: any) => id === planId);
  });
};

export const getPlanById = (planId: string | null) => (s: StoreType) => {
  const packageItem = getPackageByPlanId(planId)(s);
  return (packageItem?.plans ?? []).find(({ id }) => id === planId);
};

export const getMyPackagesItems = (s: StoreType) => s.packages.my;
export const getSimproductItems = (s: StoreType) => s.packages.simproducts;
export const getSimproductById = (simId: string | null) => (s: StoreType) => {
  const allSimproducts = getSimproductItems(s);
  return allSimproducts.find(({ id }: any) => id === simId);
};

export const getAccountItems = (s: StoreType) => s.packages.accounts;
export const getAccountById = (id: string | number) => (s: StoreType) => {
  const accounts = getAccountItems(s);
  return accounts.find((account: any) => Number(id) === account.id);
};
export const getAccountByIccid = (id: string) => (s: StoreType) => {
  const accounts = getAccountItems(s);
  return accounts.find((account: any) => id === account.iccid);
};
