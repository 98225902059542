import React from 'react';

import { Title } from '../../../components/typography';

import styles from './Info.module.scss';

interface InfoProps {
  description: string;
}

const Info = ({ description }: InfoProps) => {
  const setDesc = description.replace('Data-only', 'data-only');
  return (
    <div className={styles.info}>
      <Title className={styles.info__title} tag="h2" size="xl">Choose a package</Title>
      <p className={styles.info__subtitle}>
        {setDesc}
      </p>
    </div>
  );
};

export default Info;
