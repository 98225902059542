import { CURRENCY_SIGNS_MAP } from '../consts/currency';
import { COUNTRY_CODES_MAP } from '../consts/countries';

export type CurrencyCode = keyof typeof CURRENCY_SIGNS_MAP;
export type CountryCodes = keyof typeof COUNTRY_CODES_MAP;

export enum LocalStorageKeys {
  DeviceToken = 'deviceToken',
  AccessToken = 'accessToken',
  EsimUserEmail = 'ESIM.userEmail'
}
