import mean from 'lodash/mean';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { RouteParams } from '../../types';
import { ChartProps } from './Chart';

const getValue = ({
  value,
  meanValue,
// eslint-disable-next-line no-nested-ternary
}: {value: number, meanValue: number}) => (value === 0 ? 0 : value < meanValue ? meanValue : value);

export const useChartData = ({ details = [] }: ChartProps) => {
  const { period } = useParams<RouteParams>();
  const days = moment.unix(Number(period)).daysInMonth();
  const detailsMean = mean(details.map(({ total }) => total)) || 0;
  const meanValue = (detailsMean / 10) * 5;

  const data: any[] = Array(days)
    .fill('')
    .map((_, index) => ({
      name: `0${index + 1}`.slice(-2),
      total: 0,
      empty: meanValue,
    }));

  details.forEach(({ total, timestamp }) => {
    const day = moment.unix(timestamp).date();
    data[day - 1].total = total;
    data[day - 1].totalValue = getValue({ value: total, meanValue });

    if (total) {
      (data[day - 1].empty as any) = undefined;
    }
  });

  return data;
};
