import React from 'react';

import { Title } from '../../../components/typography';
import { Container, Row, Col } from '../../../components/grid';

import SmallCards from './small-cards';
import BigCards from './big-cards';

import styles from './PackagesMobile.module.scss';

const PacckagesMobile = () => {
  return (
    <div className={styles.content}>
      <Container>
        <Row>
          <Col>
            <Title tag="h1" size="xl" font="secondary">Packages</Title>
          </Col>
        </Row>
      </Container>
      <Row className={styles['small-cards']} noGutter>
        <Col>
          <SmallCards />
        </Col>
      </Row>
      <BigCards />
    </div>
  );
};

export default PacckagesMobile;
