import debounce from 'lodash/debounce';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const wait = (time = 300) => new Promise((res) => debounce(res, time)());

export const setMetaDesc = (content: string) => {
  // eslint-disable-next-line no-unused-expressions
  document.querySelector('meta[name="description"]')?.setAttribute('content', content);
};
