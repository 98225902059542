import React from 'react';
import { Field } from 'formik';

import Input, { InputProps } from './Input';

const ConnectedInpit = ({ name, ...rest }: InputProps) => {
  return (
    <Field name={name}>
      {({ field, meta }: any) => (
        <Input
          {...rest}
          {...field}
          error={(meta.touched && meta.error) ? meta.error : undefined}
        />
      )}
    </Field>
  );
};

export default ConnectedInpit;
