import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import { Col, Row } from '../../../components/grid';
import { Text } from '../../../components/typography';
import ProductCard from '../../../components/product-card';
import Button from '../../../components/button';
import { getCurrencySign } from '../../../utils/currency';
import { useMediaContext } from '../../../context/media';
import MobileFixedFooter from '../../../components/mobile-fixed-footer';
import { tracker } from '../../../utils/analytics';
import { ReactComponent as LockIcon } from '../../../assets/icons/lock.svg';

import { useOrderRequest, usePurchaseData } from '../hooks';
import styles from './Content.module.scss';

const Content = () => {
  const { isM } = useMediaContext();
  const { plan, sim } = usePurchaseData();

  const { onSubmit, isLoading } = useOrderRequest({ planId: plan?.id, simId: sim?.id });

  if (!plan && !sim) {
    return <Redirect to="/esim" />;
  }

  const handleSubmit = () => {
    tracker.logEvent('begin_checkout');
    onSubmit();
  };

  return (
    <Col className={styles.content} direction="column">
      <Row>
        <ProductCard {...plan} />
      </Row>
      {sim && (
        <Row>
          <ProductCard {...sim} />
        </Row>
      )}
      <Row className={styles.content__total} justify="spaceBetween">
        <Col>
          <Text size="xl" weight="medium">
            Total:
          </Text>
        </Col>
        <Col>
          <Text size="xl" weight="medium">
            {getCurrencySign()}
            {(plan?.price ?? 0) + (sim?.price ?? 0)}
          </Text>
        </Col>
      </Row>
      {!isM && (
        <Row>
          <Button
            staticIcon
            leftIcon={<LockIcon className={styles.content__lockIcon} />}
            isLoading={isLoading}
            onClick={handleSubmit}
            theme="primary"
          >
            Pay now
          </Button>

          <Row>
            <Link to="/info/card-storage-agreement" className={styles.agreement}>
              <small>
                Agreement on the Storage
                <br />
                of the Cardholder’s Credentials
              </small>
            </Link>
          </Row>

        </Row>
      )}
      <MobileFixedFooter>
        <Button
          leftIcon={<LockIcon className={styles.content__lockIcon} />}
          isLoading={isLoading}
          onClick={handleSubmit}
          theme="primary"
        >
          Pay now
        </Button>

      </MobileFixedFooter>

    </Col>
  );
};

export default Content;
