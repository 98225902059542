import { useLocation } from 'react-router-dom';
import { LocalStorageKeys } from '../../types';
import { RoutePaths } from '../../routes/types';

export default () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const deviceToken = searchParams.get('dt');
  const accessTOken = searchParams.get('at');

  if (accessTOken && deviceToken) {
    localStorage.setItem(LocalStorageKeys.DeviceToken, deviceToken);
    localStorage.setItem(LocalStorageKeys.AccessToken, accessTOken);
    window.location.href = RoutePaths.My;
  }
};
