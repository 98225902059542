import React from 'react';
import Modal, { Props } from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    maxWidth: '100%',
    overflow: 'visible',
  },
};

interface MainModalProps extends Props {
  children: any;
}

const MainModal = ({ children, ...rest }: MainModalProps) => {
  return (
    <Modal
      {...rest}
      style={customStyles}
    >
      {children}
    </Modal>
  );
};

export default MainModal;
