import React from 'react';
import cn from 'classnames';

import { Col, Row } from '../../../components/grid';
import { Title } from '../../../components/typography';

import styles from './Card.module.scss';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  children: JSX.Element | JSX.Element[] | string;
  title: string;
  titleRight?: JSX.Element | string;
}

const Card = ({
  children, title, titleRight, className, ...rest
}: CardProps) => {
  return (
    <Col {...rest} direction="column" className={cn(styles.card, { [className as string]: !!className })}>
      <Row grow justify="spaceBetween" className={styles.card__head}>
        <Col align="center">
          <Title className={styles.card__title} size="l" font="secondary">
            {title}
          </Title>
        </Col>
        {titleRight && (
          <Col align="center" className={styles.card__titleRight}>
            {titleRight}
          </Col>
        )}

      </Row>
      <Row noGutter>
        {children}
      </Row>
    </Col>
  );
};

export default Card;
