import React from 'react';

import { Col, Row } from '../../../../components/grid';
import PackagesCards from '../../../../components/packages-card';
import { tracker } from '../../../../utils/analytics';

import { Text } from '../../../../components/typography';
import { useMyPackagesCards } from '../../hooks';
import styles from './MyPackages.module.scss';

const MyPackages = () => {
  const data = useMyPackagesCards();
  const noPackages = !data.length;

  if (data.length) {
    tracker.logView('my_packages');
  }

  return (
    <Row className={styles.packages}>
      {noPackages && <Text size="xl" color="gray">No packages</Text>}
      {data.map((item) => (
        <Col key={item.id}>
          <PackagesCards {...item} />
        </Col>
      ))}
    </Row>
  );
};

export default MyPackages;
