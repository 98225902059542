import React, { useCallback, FC } from 'react';

import { Col, Row } from '../../../components/grid';
import { Text, Link } from '../../../components/typography';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';

import styles from './MobileShareBlock.module.scss';

interface Props {
  id?: string;
  qrUrl?: string;
}

const MobileShareBlock: FC<Props> = ({ id, qrUrl }) => {
  const canShare = (window.navigator as any)?.share;
  const onShare = useCallback(() => {
    (window.navigator as any).share({
      text: 'Important: eSIM can be installed only',
      title: 'eSIM QR code',
      url: qrUrl,
    });
  }, [qrUrl]);

  return (
    <Col className={styles.share} direction="column">
      <Row justify="center">
        <Text>{id}</Text>
      </Row>
      {canShare && (
        <Row justify="center">
          <Link onClick={onShare} to="#share" weight="medium">
            Send QR code
            {' '}
            <ShareIcon />
          </Link>
        </Row>
      )}
    </Col>
  );
};

export default MobileShareBlock;
