/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as RightArrow } from '../../assets/icons/big-arrow-right.svg';
import { Col, Row } from '../grid';
import Modal from '../modal';
import Button from '../button';
import Title from '../typography/Title';
import { Text } from '../typography';
import { Modals } from '../../consts/modals';
import api from '../../api';

import styles from './Menu.module.scss';
import { ConnectedInput } from '../form/input';
import { authActions } from '../../store/actions';
import { getMyProfile } from '../../store/selectors';
import { RoutePaths } from '../../routes/types';

export interface MenuItem {
  icon?: JSX.Element;
  text: string;
  action?: VoidFunction;
  modal?: Modals
}

interface MenuProps {
  items: MenuItem[];
}

const Menu = ({ items }: MenuProps) => {
  const [isOpenDelete, setOpenDelete] = useState<boolean>(false);
  const [isOpenChangeName, setOpenChangeName] = useState<boolean>(false);
  const [isOpenChangePassword, setOpenChangePassword] = useState<boolean>(false);
  const [modalDelete, setModalDelete] = useState<boolean>(false);

  const history = useHistory();

  const dispatch = useDispatch();

  const profile = useSelector(getMyProfile);

  const actionItem = (action: any, target?: Modals) => {
    if (target !== undefined) {
      if (target === Modals.DeleteAccount) {
        setOpenDelete(true);
      }
      if (target === Modals.changeName) {
        setOpenChangeName(true);
      }
      if (target === 'changePassword') {
        setOpenChangePassword(true);
      }
    } else {
      action();
    }
  };

  const changeNameForm = useFormik({
    onSubmit: (values: any) => {
      // eslint-disable-next-line no-use-before-define
      changeName(values.name);
    },
    initialValues: {
      name: '',
    },
  });

  const changePasswordForm = useFormik({
    onSubmit: (values: any) => {
      // eslint-disable-next-line no-use-before-define
      changePassword({ password: values.password, newPassword: values.newPassword });
    },
    initialValues: {
      password: '',
      newPassword: '',
    },
  });

  const {
    handleSubmit, values, handleChange, setValues, setErrors,
  } = changeNameForm;

  useEffect(() => {
    setValues({
      name: profile?.firstName,
    });
  }, [setValues, profile]);

  const {
    handleSubmit: handleSubmitForm,
    values: valuesForm,
    setValues: setValuesForm,
    handleChange: handleChangeForm,
    setErrors: setErrorsForm,
  } = changePasswordForm;

  const closeModal = (target: Modals) => {
    switch (target) {
      case Modals.changeName:
        setOpenChangeName(false);
        setValues({
          name: profile?.firstName,
        });
        break;
      case Modals.changePassword:
        setOpenChangePassword(false);
        setValuesForm({
          password: '',
          newPassword: '',
        });
        break;
      case Modals.DeleteAccount:
        setOpenDelete(false);
        break;
      default:
    }
  };

  const changeName = (name: string) => {
    api.put('/v1/my/profile', {
      firstName: name,
    }).then(() => {
      dispatch(authActions.getMyProfile());
      closeModal(Modals.changeName);
    }).catch(() => {
      setErrors({
        name: 'Invalid name',
      });
    });
  };

  const changePassword = (data: { password: string, newPassword: string }) => {
    api.post('/v1/my/password', {
      password: data.password,
      newPassword: data.newPassword,
    }).then(() => {
      closeModal(Modals.changePassword);
    }).catch(() => {
      setErrorsForm({
        password: 'Invalid password',
      });
    });
  };

  const deleteAccount = () => {
    api.delete('/v1/my').then(() => {
      setOpenDelete(false);
      setModalDelete(true);
      setTimeout(() => {
        history.push(RoutePaths.Logout);
      }, 3000);
    });
  };

  return (
    <>
      <Col className={styles.menu} noGutter direction="column">
        {items.map(({
          icon, text, action = undefined, modal,
        }, index) => (
          <Row
            noGutter
            className={styles.menu__item}
            key={index}
            onClick={() => (action ? actionItem(action, modal) : undefined)}
          >
            <Col className={styles.menu__itemIcon} justify="center" align="center">{icon}</Col>
            <Col className={styles.menu__itemText} align="center" grow>{text}</Col>
            <Col className={styles.menu__itemArrow} align="center">
              {action && (
              <RightArrow />
              )}
            </Col>
          </Row>
        ))}
      </Col>
      <Modal
        isOpen={isOpenDelete}
        shouldCloseOnOverlayClick
        onRequestClose={() => closeModal(Modals.DeleteAccount)}
      >
        <div className={styles.modal}>
          <Title size="xs">
            Delete your account will:
          </Title>
          <Text>
            <ul>
              <li>Removing all personal data</li>
              <li>Annul the remaining balance</li>
              <li>Cancel the eSIM</li>
            </ul>
          </Text>

          <Button
            theme="secondary-light"
            small
            style={{ marginRight: '10px' }}
            onClick={() => closeModal(Modals.DeleteAccount)}
          >
            Cancel
          </Button>
          <Button
            theme="secondary-dark"
            small
            onClick={deleteAccount}
          >
            Delete Account
          </Button>
        </div>
      </Modal>
      <Modal isOpen={modalDelete}>
        <div className={styles.modal}>
          <Title size="xs">
            Account Deactivation
          </Title>
          <div>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            We've send you the link to delete your account via email.
          </div>
          <div>
            Please check your mailbox.
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenChangeName}
        shouldCloseOnOverlayClick
        onRequestClose={() => closeModal(Modals.changeName)}
      >
        <div className={styles.modal}>
          <Title size="xs">
            Change Name
          </Title>
          <form
            onSubmit={handleSubmit}
          >
            <FormikProvider value={changeNameForm}>
              <ConnectedInput
                theme="underline"
                name="name"
                placeholder="Name"
                onChange={handleChange}
                value={values.name}
              />
              <Button
                theme="secondary-light"
                small
                style={{ marginRight: '10px' }}
                onClick={() => closeModal(Modals.changeName)}
              >
                Cancel
              </Button>
              <Button
                theme="secondary-dark"
                small
                disabled={values.name === '' || values.name === profile?.firstName}
                onClick={() => handleSubmit()}
              >
                Change Name
              </Button>
            </FormikProvider>

          </form>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenChangePassword}
        shouldCloseOnOverlayClick
        onRequestClose={() => closeModal(Modals.changePassword)}
      >
        <div className={styles.modal}>
          <Title size="xs">
            Change Password
          </Title>
          <form
            onSubmit={handleSubmitForm}
          >
            <FormikProvider value={changePasswordForm}>
              <ConnectedInput
                theme="underline"
                type="password"
                name="password"
                placeholder="Password"
                onChange={handleChangeForm}
                value={valuesForm.password}
              />
              <ConnectedInput
                theme="underline"
                type="password"
                name="newPassword"
                placeholder="New password"
                onChange={handleChangeForm}
                value={valuesForm.newPassword}
              />
              <Button
                theme="secondary-light"
                small
                style={{ marginRight: '10px' }}
                onClick={() => closeModal(Modals.changePassword)}
              >
                Cancel
              </Button>
              <Button
                theme="secondary-dark"
                small
                disabled={valuesForm.password === '' || valuesForm.newPassword === ''}
                onClick={() => handleSubmitForm()}
              >
                Change Password
              </Button>
            </FormikProvider>

          </form>
        </div>
      </Modal>
    </>
  );
};

export default Menu;
