import firebase from 'firebase/app';
import 'firebase/analytics';

export function getPlatform() {
  const { userAgent } = navigator;
  let platform = '';
  if (/android/i.test(userAgent)) platform = '_android';
  if (/iPhone/.test(userAgent)) platform = '_iphone';
  return window.cordova ? `native${platform}` : `web${platform}`;
}

export const tracker = {

  initialize() {
    document.addEventListener('userloaded', this.onUserLoaded.bind(this), false);
    this.initAnalytics();
  },

  onUserLoaded(e) {
    const user = e.detail;
    if (user) {
      firebase.analytics().setUserId(user.id);
    }
  },

  initAnalytics() {
    const firebaseConfig = {
      apiKey: 'AIzaSyA3fUDoxCOCDQpIsEjOus7S6sK0oH9Hcqg',
      authDomain: 'esim-97ff1.firebaseapp.com',
      databaseURL: 'https://esim-97ff1.firebaseio.com',
      projectId: 'esim-97ff1',
      storageBucket: 'esim-97ff1.appspot.com',
      messagingSenderId: '775530588850',
      appId: '1:775530588850:web:446620ae0e12f7d00a77ab',
      measurementId: 'G-LNV2RXCV7W',
    };

    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  },

  logEvent(event, params) {
    if (params) {
      // eslint-disable-next-line no-param-reassign
      params.platform = getPlatform();
    }
    console.info('logEvent', event);
    firebase.analytics().logEvent(event, params);

    if (!event.startsWith('view_')) {
      if (window.intercom) {
        window.intercom.logEvent(event, params);
      } else if (window.Intercom) {
        window.Intercom('trackEvent', event, params);
      }
    }
  },

  logView(viewItem) {
    this.logEvent('view_item', { screen_name: viewItem });
    this.logEvent(`view_${viewItem}`);
  },

};

tracker.initialize();
