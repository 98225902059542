import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import Layout from '../../../../components/layout';
import Modal from '../../../../components/modal';
import { RoutePaths } from '../../../../routes/types';
import Input from '../../../../components/form/input';
import { isEmail, isNotEmptyString } from '../../../../utils/validators';
import Button from '../../../../components/button';
import { getDeviceToken } from '../../../../store/selectors';
import { useStores } from '../../../../store/mobx/useStore';
import { LocalStorageKeys } from '../../../../types';
import s from './EmailForm.module.scss';
import styles from '../modal-mobile-layout/ModalMobileLayout.module.scss';

export const INITIAL_VALUES = {
  email: '',
};

function validator({ email }: typeof INITIAL_VALUES) {
  const errors = {} as typeof INITIAL_VALUES;
  if (!isNotEmptyString(email)) {
    errors.email = 'Email is required';
  } else if (!isEmail(email)) {
    errors.email = 'Email is invalid';
  }
  return errors;
}

const EmailForm = () => {
  const history = useHistory();
  const onClose = useCallback(() => history.push('/'), [history]);

  const deviceToken = useSelector(getDeviceToken) as string;
  const { userStore: { sendEmailAsync } } = useStores();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    // eslint-disable-next-line no-use-before-define
    onSubmit: (values) => submit(values.email),
    validateOnChange: false,
    validate: validator,
  });

  const {
    handleSubmit, handleChange, errors, setErrors,
  } = formik;

  const submit = (email: string) => {
    sendEmailAsync(email, deviceToken).then(() => {
      localStorage.setItem(LocalStorageKeys.EsimUserEmail, email);
      history.push(`${RoutePaths.Verify}?email=${email}`);
    }).catch((err) => {
      const { response } = err;
      if (response) {
        const { data } = response;
        setErrors({ email: data.message });
      } else {
        setErrors({ email: 'Something wrong. Please try again later' });
      }
    });
  };

  const onChange = (e: unknown) => {
    setErrors({ email: '' });
    handleChange(e);
  };

  return (
    <Modal isOpen shouldCloseOnOverlayClick onRequestClose={onClose}>
      <div className={styles.modal}>
        <div className={styles.modal__topBg} />
        <Layout
          title="Login"
          headerProps={{ backLink: RoutePaths.SignIn, action: () => onClose() }}
          className={styles.modal__layout}
        >
          <div className={s.wrapper}>
            <div className={s.title}>Email address</div>
            <div className={s.info}>Please enter your email to continue</div>
            <form onSubmit={handleSubmit}>
              <Input
                theme="underline"
                name="email"
                placeholder="Email address"
                onChange={onChange}
                error={errors.email}
              />
              <Button
                theme="secondary-dark"
                onClick={() => handleSubmit()}
                className={s.btn}
              >
                Continue
              </Button>
            </form>
          </div>

        </Layout>
      </div>
    </Modal>
  );
};

export default EmailForm;
