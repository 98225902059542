import React from 'react';
import cn from 'classnames';

import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';

import styles from './CountryCard.module.scss';

export interface CountryCardProps {
  country: 'usa' | 'asia' | 'world' | 'europe';
  cost: number;
  count: number | string;
  title: string;
  small?: boolean;
  img: {
    small: string;
    medium: string;
  };
  checked?: boolean;
  unit?: string;
}

const CountryCard = ({
  country, title, count, cost, small, img, checked, unit = 'Gb',
}: CountryCardProps) => {
  const isChecked = checked === true;
  const isNotChecked = checked === false;

  return (
    <div className={cn(styles.card, styles[`card_${country}`], {
      [styles.card_small]: small,
      [styles.card_checked_true]: isChecked,
      [styles.card_checked_false]: isNotChecked,
    })}
    >
      {isChecked && <i className={styles.check}><CheckIcon className={styles.check_icon} /></i>}
      <div className={styles.card__content}>
        <h3 className={styles.title}>{title}</h3>
        {!small && (
          <>
            <p className={styles.card__count}>
              {count > 1 && `${count} countries`}
            </p>
            <div className={styles.card__cost}>
              <div>from</div>
              <div className={styles.card__price}>
                {`€${cost.toFixed(2)} / ${unit}`}
              </div>
            </div>
          </>
        )}
      </div>
      <img
        src={small ? img.small : img.medium}
        alt={title}
        className={cn(
          styles.image,
          styles[`image_${country}`],
        )}
      />
    </div>
  );
};

export default CountryCard;
