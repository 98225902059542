import React from 'react';
import cn from 'classnames';

import { Title } from '../../typography';
import { Container } from '../../grid';

import styles from './Intro.module.scss';

const Intro = ({ title }: {title: string}) => {
  return (
    <div className={cn(styles.intro)}>
      <Container main>
        <Title className={styles.intro__title} tag="h1" size="xxl" font="secondary">
          {title}
        </Title>
      </Container>
    </div>
  );
};

export default Intro;
