/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import * as thunks from './thunks';
import { AllPackagesItem } from '../packages/types';
import { UsageStoreState } from './types';
// import { FAKE_USAGE } from './const';
import { mapUsageToStore } from './mappers';

const packageSlice = createSlice({
  name: 'package',
  initialState: {
    isLoading: false,
    item: {} as AllPackagesItem,
    usage: {} as UsageStoreState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunks.getPackage.pending, (state) => {
      state.isLoading = true;
    }).addCase(thunks.getPackage.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.item = payload;
    });

    builder.addCase(thunks.getUsage.fulfilled, (state, { payload }) => {
      state.usage = mapUsageToStore(payload);
    });
  },
});

export const { reducer } = packageSlice;
export const actions = { ...packageSlice.actions, ...thunks };
export default packageSlice;
