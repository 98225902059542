import React from 'react';
import cn from 'classnames';

import styles from './Text.module.scss';

export interface TextProps extends React.HTMLAttributes<HTMLSpanElement> {
  tag?: string;
  color?: 'blue' | 'black' | 'gray' | 'lightblue';
  size?: 'xxl' | 'xl' | 'l' | 'm' | 'sm';
  weight?: 'thin' | 'bold' | 'medium';
}

const Text = ({
  children, tag, className, color = 'black', size, weight = 'thin', ...rest
}: TextProps) => {
  const Tag = tag || 'span' as any;

  return (
    <Tag
      {...rest}
      className={cn(styles.text, styles[`text_color_${color}`], {
        [className as string]: !!className,
        [styles[`text_size_${size}`]]: !!size,
        [styles[`text_weight_${weight}`]]: !!weight,
      })}
    >
      {children}
    </Tag>
  );
};

export default Text;
