import React from 'react';
import cn from 'classnames';

import { Title, Text } from '../../../components/typography';
import Breads, { BreadItem } from '../../../components/layout/breads';
import { Container } from '../../../components/grid';
import qrPhone from '../../../assets/images/installation/qrphone@2x.png';

import styles from './Intro.module.scss';

const BREADS: BreadItem[] = [];

interface Props {
  qrUrl?: string;
}

const Intro = ({ qrUrl }: Props) => {
  return (
    <div className={cn(styles.intro)}>
      <Breads className={styles.intro__breads} items={BREADS} />
      <Container className={styles.intro__container} main>
        <Title className={styles.intro__title} tag="h1" size="xxl" font="secondary">Installation</Title>
        <Text className={styles.intro__subtitle} size="xl" color="gray" weight="thin">
          Open your mobile phone Settings
          and&nbsp;follow&nbsp;the&nbsp;instructions below.
          <p>
            <Text weight="medium" color="gray">Important:</Text>
            &nbsp;eSIM&nbsp;can be installed only once!
          </p>
        </Text>

        <div className={styles.intro__qr}>
          <img className={styles.intro__qrPhone} src={qrPhone} alt="Phone" />
          <img className={styles.intro__qrImage} src={qrUrl} alt="QR code" />
        </div>
      </Container>
    </div>
  );
};

export default Intro;
