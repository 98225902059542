import React, { useCallback } from 'react';
import cn from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as LoginImg } from '../../../../assets/images/login.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';

import Modal from '../../../../components/modal';
import { Row, Col } from '../../../../components/grid';
import Button from '../../../../components/button';
import { Title } from '../../../../components/typography';

import { BUTTONS } from '../consts';
import styles from './ModalDesktopLayout.module.scss';
import { ModalTypes } from '../types';
import { useOauth } from '../hooks';
import { ReactComponent as EmailIcon } from '../../../../assets/icons/email.svg';
import { RoutePaths } from '../../../../routes/types';

interface ModalDesktopLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  type: ModalTypes;
}

const ModalDesktopLayout = ({
  children,
}: ModalDesktopLayoutProps) => {
  const { isLoading, clickOauth, provider } = useOauth();
  const history = useHistory();
  const onClose = useCallback(() => history.push('/'), [history]);

  return (
    <Modal isOpen shouldCloseOnOverlayClick onRequestClose={onClose}>
      <div className={styles.close}>
        <CloseIcon onClick={onClose} />
      </div>
      <section className={styles.modal}>
        <Row noGutter className={styles.modal__wrapper}>
          <Col className={cn(styles.side, styles.side_left)}>
            <div className={styles.img}>
              <LoginImg />
            </div>
          </Col>
          <Col className={cn(styles.side, styles.side_right)} grow>
            <Title className={styles.side__title} size="xl">Welcome!</Title>
            {children}
            <div className={styles.otherAuth}>
              {BUTTONS.map(({ Icon, text, provider: p }) => (
                <Col key={text} className={styles.authBtn}>
                  <Button
                    onClick={() => clickOauth(p)}
                    isLoading={provider === p && isLoading}
                    className={styles.fixedPositionCenter}
                    leftIcon={<Icon />}
                    smallPadding
                    theme="secondary-light"
                  >
                    {text}
                  </Button>
                </Col>
              ))}
              <Col className={styles.authBtn}>
                <Button
                  theme="secondary-light"
                  className={styles.fixedPositionCenter}
                  onClick={() => history.push(RoutePaths.Email)}
                  smallPadding
                  leftIcon={<EmailIcon />}
                >
                  Continue with Email
                </Button>
              </Col>
              <div className={styles.info}>
                By clicking ‘Continue‘ you are accepting
                {' '}
                <br />
                <Link to={RoutePaths.TermsOfUse}>Terms Of Use</Link>
                {' '}
                and
                <Link to={RoutePaths.Policy}> Privacy Policy</Link>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </Modal>
  );
};

export default ModalDesktopLayout;
