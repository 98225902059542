import React from 'react';

import { Row } from '../grid';
import { Title, Text } from '../typography';
import { ReactComponent as EuropeImage } from '../../assets/images/country-cards-white/europe.svg';
import { ReactComponent as WorldImage } from '../../assets/images/country-cards-white/world.svg';
import { ReactComponent as AsiaImage } from '../../assets/images/country-cards-white/asia.svg';
import { ReactComponent as UsaImage } from '../../assets/images/country-cards-white/america.svg';
import { AllPackagesItem } from '../../store/reducers/packages';
import { getMinimalPricePlan } from '../../utils/packages';
import { formatDataAmount } from '../../utils/format';

import { getCurrencySign } from '../../utils/currency';
import styles from './CountryCardWhite.module.scss';

interface CountryCardWhiteProps {
  item: AllPackagesItem;
}

const IMAGES_MAP = {
  asia: AsiaImage,
  usa: UsaImage,
  'north-america': UsaImage,
  europe: EuropeImage,
  world: WorldImage,
};

const CountryCardWhite = ({ item }: CountryCardWhiteProps) => {
  const {
    name, countries = [], slug, plans,
  } = item;
  const plan = getMinimalPricePlan(plans);
  const { amount, unit } = formatDataAmount(plan?.volume);
  const currencySign = getCurrencySign();
  const Image = IMAGES_MAP[slug] || WorldImage;
  const cost = plan?.price / amount;

  return (
    <Row direction="column" className={styles.card}>
      <Row className={styles.card__image}>
        <Image />
      </Row>
      <Row className={styles.card__title} noGutter justify="center">
        <Title tag="h3" size="m">{name}</Title>
      </Row>
      <Row className={styles.card__desc} noGutter justify="center">
        <Text tag="p" size="m" color="gray">
          {countries.length > 1 && `${countries.length} countries`}
        </Text>
      </Row>
      <Row className={styles.card__count} noGutter justify="center">
        <Text tag="p" size="l" color="black" weight="medium">
          {`from ${currencySign} ${Math.ceil(cost * 100) / 100} / ${unit}`}
        </Text>
      </Row>
    </Row>
  );
};

export default CountryCardWhite;
