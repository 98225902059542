import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useRequest } from '../../hooks/common';
import { packagesActions } from '../../store/actions';
import { getAccountItems } from '../../store/selectors';
import { ReactComponent as SimIcon } from '../../assets/icons/settings/sim.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/settings/plus.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/settings/info.svg';
import { ReactComponent as ContactSupportIcon } from '../../assets/icons/contact-support.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/settings/logout.svg';
import { MenuItem } from '../../components/menu/Menu';
import { Modals } from '../../consts/modals';

export const useScreenData = () => {
  const { isLoading, sendRequest } = useRequest(
    packagesActions.getMyAccounts, { initialLoadingState: true },
  );

  useEffect(() => {
    sendRequest({});
  }, [sendRequest]);

  return isLoading;
};

export const useAccountsMenuItem = () => {
  const history = useHistory();
  const accounts = useSelector(getAccountItems);
  const accountItems: MenuItem[] = (accounts as any[]).map(({ msisdn, iccid }) => ({
    icon: <SimIcon />,
    text: `${msisdn}`,
    action: () => history.push(`/installation/${iccid}`),
  }));

  accountItems.push({ icon: <PlusIcon />, text: 'Buy new', action: () => history.push('/esim') });

  return accountItems;
};

export const useAppMenu = () => {
  const history = useHistory();

  const showCrispChat = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const crispControl = window.$crisp;
    crispControl.push(['do', 'chat:show']);
    crispControl.push(['do', 'chat:open']);
  };

  return [
    { icon: <ContactSupportIcon />, text: 'Contact support', action: () => showCrispChat() },
    { icon: <InfoIcon />, text: 'About Yolla eSIM', action: () => history.push('/about') },
    {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      icon: <DeleteIcon />, text: 'Delete Account', action: () => {}, modal: Modals.DeleteAccount,
    },
    { icon: <LogoutIcon />, text: 'Log out', action: () => history.push('/logout') },
  ];
};
