import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import cn from 'classnames';

import { Col, Container, Row } from '../../grid';
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo.svg';
import { ReactComponent as WhiteLogoIcon } from '../../../assets/icons/logo-white.svg';
import { ReactComponent as BackIcon } from '../../../assets/icons/back.svg';
import { useMediaContext } from '../../../context/media';

import AuthAction from './AuthAction';

import styles from './Header.module.scss';

interface Props {
  darkBg: boolean;
  action?: any;
  backLink?: string;
}

const Header = ({ darkBg, backLink, action }: Props) => {
  const history = useHistory();
  const { isM } = useMediaContext();
  const Logo = darkBg ? WhiteLogoIcon : LogoIcon;
  const onBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <header className={styles.header}>
      <Container main>
        <Row noGutter>
          <Col align="center" grow>
            {(isM && backLink)
              ? (
                <BackIcon
                  onClick={onBackClick}
                  className={cn(styles.backLink, { [styles.backLink_dark]: !darkBg })}
                />
              )
              : <Link to="/"><Logo className={styles.logo} /></Link>}
          </Col>
          <Col align="center" justify="center">
            {(isM && action !== undefined)
              ? action
              : <AuthAction darkBg={darkBg} />}
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
