import React from 'react';

import StaticPageLayout, { TextRow, TitleRow } from '../../components/static-page-layout';
import { Col } from '../../components/grid';

const CardStorageAgreement = () => {
  return (
    <StaticPageLayout title="Agreement">
      <Col noGutter direction="column">
        <TitleRow>
          on the Storage of the Cardholder’s Credentials
        </TitleRow>
        <br />
        <br />
        <TextRow>
          This Agreement on the Storage of the Cardholder’s Credentials (hereinafter – the “Agreement”) is entered into
          between you (hereinafter also referred to as – the “Cardholder”) and Yolla Calls International OÜ (hereinafter
          – the “Merchant”).
          By ticking the relevant tick-box and clicking the “Ok” button, you express full consent to this Agreement and
          authorize the Merchant and its payment processing service provider to store your name, surname, card expiry
          date and PAN number (hereinafter – the Credentials on File (COF)) and to initiate transactions on your behalf
          (hereinafter – Merchant-Initiated COF Transactions) and/or to process transactions initiated by you
          (hereinafter – Cardholder-Initiated COF Transactions), both by using the Credentials of File, as indicated
          below.
        </TextRow>

        <TextRow>
          Disclosures of the Merchant:
        </TextRow>

        <TextRow>
          3.1. Your Credentials will be used for: Merchant-Initiated COF Transactions and Cardholder-initiated COF
          Transactions.
        </TextRow>

        <TextRow>
          3.2. Description of services: An app selling of electronic SIM cards with data (data plans or data bundles).
        </TextRow>

        <TextRow>
          3.3. Fixed date or interval of the Transaction: N/A.
        </TextRow>

        <TextRow>
          3.4. Event that will prompt the Transaction: The Transaction will be initiated when your purchased data plan
          will end.
        </TextRow>

        <TextRow>
          The following details in relation to this Agreement shall be included in the transaction confirmation: card
          number, transaction amount, surcharges (if applicable), transaction currency, transaction date, authorisation
          code, the Merchant’s location.
        </TextRow>

        <TextRow>
          You will be notified of any changes to the present Agreement 7 (seven) business days before such changes
          become effective in relation to scheduled (with fixed, regular intervals) Merchant-Initiated COF Transactions,
          and 2 (two) business days before such changes become effective in relation to Merchant-Initiated COF
          Transactions which are unscheduled or in relation to Cardholder-Initiated COF Transactions.
        </TextRow>

        <TextRow>
          The Agreement shall enter into force after successful authorisation (or account verification) by your card
          issuer and receipt of your consent to this Agreement by the Merchant, and shall be valid until terminated by
          you or by the Merchant according to the Cancellation policy.
        </TextRow>

        <TextRow>
          The Cancellation and refund policies of the Merchant are accessible following the link below:
          https://esim.yollacalls.com/info/tou.
        </TextRow>

        <TextRow>
          In case of any inquiries related to this Agreement, please contact us in the app.
        </TextRow>
      </Col>
      <br />
      <br />
      <br />
    </StaticPageLayout>
  );
};

export default CardStorageAgreement;
