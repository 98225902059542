import React, { useLayoutEffect, useState } from 'react';
import {
  Redirect, Route, Switch, useHistory, useLocation,
} from 'react-router-dom';

import { useSelector } from 'react-redux';
import { LocalStorageKeys } from '../types';
import ScreenLoader from '../components/layout/screen-loader';

import { usePrepareData } from './hooks';
import { RoutePaths } from './types';
import { useMediaContext } from '../context/media';
import { getLoading, getMyProfile } from '../store/selectors';

import Usage from '../screens/usage';
import Packages from '../screens/packages';
import Package from '../screens/package';
import Logout from '../screens/logout';
import Esim from '../screens/esim';
import Purchase from '../screens/purchase';
import Installation from '../screens/installation';
import NotFound from '../screens/not-found';
import Faq from '../screens/faq';
import Contacts from '../screens/contacts';
import TermsOfUse from '../screens/terms-of-use';
import CardStorageAgreement from '../screens/card-storage-agreement';
import Policy from '../screens/policy';
import About from '../screens/about/about';
import Oauth from '../screens/oauth';
import Landing from '../screens/landing';
import Settings from '../screens/settings';
import ChoosePackage from '../screens/choose-package';
import api from '../api';

const Routes = () => {
  const [accessToken, setAccessToken] = useState<string | null>(window.localStorage.getItem(LocalStorageKeys.AccessToken));
  const [isLoading, setLoading] = useState<boolean>(true);

  usePrepareData();

  const history = useHistory();

  const { isM } = useMediaContext();

  const profile = useSelector(getMyProfile);
  const userLoading = useSelector(getLoading);

  const { pathname, search } = useLocation();

  const changeToken = () => {
    const token = localStorage.getItem(LocalStorageKeys.AccessToken);
    setAccessToken(token);
  };

  useLayoutEffect(() => {
    window.addEventListener('storage', changeToken);
    return () => {
      window.removeEventListener('storage', changeToken);
    };
  }, []);

  useLayoutEffect(() => {
    document.addEventListener('deviceready', () => {
      setLoading(false);
    }, false);
  }, [history, profile]);

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const crisp = window.$crisp;
    if (isM) {
      crisp.push(['do', 'chat:hide']);
    } else {
      crisp.push(['do', 'chat:show']);
    }
  }, [isM]);

  useLayoutEffect(() => {
    const params = new URLSearchParams(search);
    const simId = params.get('simId');
    if (profile?.email && accessToken) {
      if (simId === null
        && pathname !== RoutePaths.Settings
        && pathname !== RoutePaths.Logout) {
        if (!pathname.includes('usage')) {
          setLoading(true);
        }
        api.get('/v1/my/accounts')
          .then((res) => {
            const { data } = res;
            if (data.length === 0) {
              history.push(RoutePaths.Esim);
            }
          }).finally(() => {
            setLoading(false);
          });
      }
    }
  }, [search, history, pathname, accessToken, profile]);

  return (
    <>
      {(isLoading || userLoading) && <ScreenLoader />}
      <Switch>
        {/* Если юзер авторизован - со страницы лендоса редиректим на страницу пакетов */}
        {!!accessToken && <Redirect exact from={RoutePaths.Landing} to={RoutePaths.My} />}
        <Route path={RoutePaths.Oauth} component={Oauth} />
        <Route
          exact
          path={[RoutePaths.Landing, RoutePaths.Email, RoutePaths.SignIn, RoutePaths.Verify, RoutePaths.Login, RoutePaths.SignUp]}
          component={Landing}
        />
        <Route path={RoutePaths.Settings} component={Settings} />
        <Route path={RoutePaths.Usage} component={Usage} />
        <Route exact path={RoutePaths.My} component={Packages} />
        <Route path={RoutePaths.Package} component={Package} />
        <Route exact path={RoutePaths.Esim} component={Esim} />
        <Route exact path={RoutePaths.Purchase} component={Purchase} />
        <Route exact path={RoutePaths.Installation} component={Installation} />
        <Route exact path={RoutePaths.ChoosePackage} component={ChoosePackage} />
        <Route exact path={RoutePaths.Logout} component={Logout} />
        <Route exact path={RoutePaths.About} component={About} />
        <Route exact path={RoutePaths.Faq} component={Faq} />
        <Route exact path={RoutePaths.Contacts} component={Contacts} />
        <Route exact path={RoutePaths.TermsOfUse} component={TermsOfUse} />
        <Route exact path={RoutePaths.CardStorageAgreement} component={CardStorageAgreement} />
        <Route exact path={RoutePaths.Policy} component={Policy} />

        <Route exact path={['*', RoutePaths.NotFound]} component={NotFound} />
      </Switch>
    </>
  );
};

export default Routes;
