import React, { useEffect } from 'react';

import StaticPageLayout from '../../components/static-page-layout';
import { Col, Row } from '../../components/grid';
import { usePackagesCards } from '../packages/hooks';
import CountryCard from '../../components/country-card';
import { useMediaContext } from '../../context/media';
import Button from '../../components/button';

import { useLoadData, useScreenActions } from './hooks';
import styles from './ChoosePackage.module.scss';

const ChoosePackage = () => {
  const { isM } = useMediaContext();
  const {
    pack, setPackage, onContinueClick,
  } = useScreenActions();
  const data = usePackagesCards();
  const isLoading = useLoadData();

  useEffect(() => {
    if (data.length) {
      setPackage(data[0]);
    }
  }, [data, setPackage]);

  return (
    <StaticPageLayout className={styles.screen} isLoading={isLoading} noLimitWidth title="Choose package">
      <Row direction={isM ? 'column' : 'row'} className={styles.screen__cards}>
        {data.map((item: any) => (
          <Col
            className={styles.screen__card}
            noGutter={isM}
            onClick={() => setPackage(item)}
            key={item.id}
          >
            <CountryCard checked={item.id === pack?.id} {...item} />
          </Col>
        ))}
      </Row>
      <Row className={styles.screen__btns} justify="spaceBetween">
        <Col grow={isM}>
          <Button
            smallPadding={isM}
            fluid={isM}
            onClick={onContinueClick}
            disabled={!pack}
            theme="primary"
          >
            Continue
          </Button>
        </Col>
        <Col grow={isM} />
      </Row>
    </StaticPageLayout>
  );
};

export default ChoosePackage;
