import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Layout from '../../components/layout';
import { Container } from '../../components/grid';
import { useMediaContext } from '../../context/media';
import { getAccountByIccid } from '../../store/selectors';

import Content from './content';
import Intro from './intro';
import { useInstallationData } from './hooks';

import styles from './Installation.module.scss';

const Installation = () => {
  const { isLoading } = useInstallationData();
  const { isM } = useMediaContext();
  const { id } = useParams<{id: string}>();
  const account = useSelector(getAccountByIccid(id));

  return (
    <Layout
      headerProps={{ backLink: '/', action: null }}
      className={styles.screen}
      title="Installation"
      isLoading={isLoading}
      darkBg={isM}
    >
      <Intro qrUrl={account?.qrUrl} />
      <Container main className={styles.content}>
        <Content account={account} id={id} />
      </Container>
    </Layout>
  );
};

export default Installation;
