import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import Layout from '../../components/layout';
import { Col, Container, Row } from '../../components/grid';
import { Text } from '../../components/typography';
import { tracker } from '../../utils/analytics';
import Menu from '../../components/menu';

import { ReactComponent as UserIcon } from '../../assets/icons/settings/user.svg';
import { ReactComponent as ChangeIcon } from '../../assets/icons/settings/change-password.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/settings/email.svg';
import { useAccountsMenuItem, useAppMenu, useScreenData } from './hooks';
import { getMyProfile } from '../../store/selectors';
import { useMediaContext } from '../../context/media';
import { Modals } from '../../consts/modals';
import Intro from './intro';

import s from './Settings.module.scss';

const Settings = () => {
  useEffect(() => {
    tracker.logView('settigns');
  }, []);

  const isLoading = useScreenData();
  const accountsItems = useAccountsMenuItem();
  const appItems = useAppMenu();
  const profile = useSelector(getMyProfile);

  const user = `${profile ? profile?.firstName || '' : ''} ${profile ? profile?.lastName || '' : ''}`;
  const email = `${profile ? profile?.email || '' : ''}`;

  const ACCOUNT_MENU = [
    {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      icon: <UserIcon />, text: `${user}`, modal: Modals.changeName, action: () => {},
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      icon: <ChangeIcon />, text: 'Change password', modal: Modals.changePassword, action: () => {},
    },
    { icon: <EmailIcon />, text: `${email}` },
    // { icon: <CardIcon />, text: 'Payment settings', action: () => history.push('/') },
  ];

  const { isM } = useMediaContext();

  useEffect(() => {
    return () => {
      if (isM) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const crispControl = window.$crisp;
        crispControl.push(['do', 'chat:hide']);
      }
    };
  }, [isM]);

  return (
    <Layout
      headerProps={{ backLink: '/', action: null }}
      className={s.screen}
      title="Settings"
      isLoading={isLoading}
    >
      <Intro />
      <Container main className={s.content}>
        <Col noGutter className={s.menu} direction="column">
          <Row>
            <Text size="l" color="gray">Account</Text>
          </Row>
          <Row>
            <Menu items={ACCOUNT_MENU} />
          </Row>
        </Col>

        <Col noGutter className={s.menu} direction="column">
          <Row>
            <Text size="l" color="gray">My eSIMs</Text>
          </Row>
          <Row>
            <Menu items={accountsItems} />
          </Row>
        </Col>

        <Col className={s.menu} direction="column">
          <Row>
            <Text size="l" color="gray">App</Text>
          </Row>
          <Row>
            <Menu items={appItems} />
          </Row>
        </Col>
      </Container>
    </Layout>
  );
};

export default Settings;
