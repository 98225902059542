import axios, { AxiosTransformer } from 'axios';
import { LocalStorageKeys } from '../types';

const BASE_URL = process.env.REACT_APP_API_BACKEND ? process.env.REACT_APP_API_BACKEND : '/api';

const api = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  transformRequest: [(data, headers) => {
    const token = localStorage.getItem(LocalStorageKeys.AccessToken);
    // eslint-disable-next-line no-param-reassign
    headers.common.Authorization = `Bearer ${token}`;
    return data;
  }, ...axios.defaults.transformRequest as AxiosTransformer[]],
});

export default api;
