import React from 'react';
import cn from 'classnames';

import styles from './Grid.module.scss';

interface Props {
  children: any;
  className?: string;
  main?: boolean;
}

const Container = ({ children, className, main }: Props) => {
  return (
    <div className={cn(styles.container, {
      [className as string]: !!className,
      [styles.container_main]: !!main,
    })}
    >
      {children}
    </div>
  );
};

export default Container;
