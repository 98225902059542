import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Container, Col, Row } from '../../../../components/grid';
import { Title, Text } from '../../../../components/typography';
import Button from '../../../../components/button';
import image from '../../../../assets/images/landing/intro-img.svg';

import styles from './Intro.module.scss';

interface Props {
  btsContainer: React.RefObject<HTMLDivElement>
}

const Intro = ({ btsContainer }: Props) => {
  const onDownloadClick = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    btsContainer?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [btsContainer]);

  return (
    <Container main className={styles.intro}>
      <Row className={styles.intro__wrapper}>
        <Col noGutter justify="center" direction="column">
          <Row>
            <Title className={styles.intro__title} tag="h1" size="xxl">
              eSIM data plans
              {' '}
              <Text color="blue" weight="medium">at&nbsp;local&nbsp;rates</Text>
              {' '}
              {' '}
              without&nbsp;physical
              <br />
              SIM card
            </Title>
          </Row>
          <Row className={styles.intro__buttons}>
            <Col>
              <Link to="/signup">
                <Button theme="primary">Sign up</Button>
              </Link>
            </Col>
            <Col>
              <Button onClick={onDownloadClick} theme="outline">Download app</Button>
            </Col>
          </Row>
        </Col>
        <Col className={styles.intro__image}>
          <img className={styles.intro__imageMain} srcSet={`${image}`} alt="Intro phone" />
        </Col>
      </Row>
    </Container>
  );
};

export default Intro;
