import React from 'react';
import { Link } from 'react-router-dom';

import { Col, Row } from '../../../../components/grid';
import CountryCard from '../../../../components/country-card';
import { Title } from '../../../../components/typography';

import { usePackagesCards } from '../../hooks';
import styles from './AllPackages.module.scss';

const AllPackages = () => {
  const data = usePackagesCards();

  return (
    <div className={styles.packages}>
      <Row>
        <Title size="l" tag="h2" font="secondary">
          More packages
        </Title>
      </Row>
      <Row className={styles.cards}>
        {data.map((item: any) => (
          <Col key={item.id}>
            <Link to={`/packages/${item.slug}`}>
              <CountryCard {...item} />
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default AllPackages;
