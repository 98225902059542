import React from 'react';
import cn from 'classnames';

import { Row } from '../grid';
import { useMediaContext } from '../../context/media';

import styles from './MobileFixedFooter.module.scss';

interface MobileFixedFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const MobileFixedFooter = ({ children, className }: MobileFixedFooterProps) => {
  const { isM } = useMediaContext();

  if (!isM) {
    return null;
  }

  return (
    <Row
      className={cn(styles.footer, { [className as string]: !!className })}
      direction="column"
      noGutter
    >
      {children}
    </Row>
  );
};

export default MobileFixedFooter;
