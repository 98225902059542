import React, { FC, ReactElement } from 'react';

import Drawer from '../../../components/drawer';

type Props = {
  data: {title: string, content: string | ReactElement}[]
}

// eslint-disable-next-line react/prop-types
const Faq:FC<Props> = ({ data }) => {
  return (
    <Drawer items={data} />
  );
};

export default Faq;
