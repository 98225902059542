import React, { useCallback, useEffect, useRef } from 'react';
import moment from 'moment';
import noop from 'lodash/noop';
import cn from 'classnames';
import { useHistory, useParams } from 'react-router-dom';

import { Col, Row } from '../../../components/grid';
import Button from '../../../components/button';
import { Text } from '../../../components/typography';

import { useMediaContext } from '../../../context/media';
import { RouteParams } from '../types';
import { useSelectorItems } from './hooks';

import styles from './PeriodSelector.module.scss';

const PeriodSelector = () => {
  const { isM } = useMediaContext();
  const currentDateRef = useRef<HTMLButtonElement>();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { period, packageSlug } = useParams<RouteParams>();
  const history = useHistory();
  const res = useSelectorItems();
  const changePeriod = useCallback((itemPeriod: string) => {
    const timestamp = moment(itemPeriod).unix();
    history.push(`/usage/${packageSlug}/${timestamp}`);
  }, [history, packageSlug]);

  useEffect(() => {
    if (isM) {
      // eslint-disable-next-line no-unused-expressions
      wrapperRef?.current?.scrollIntoView({ behavior: 'smooth', inline: 'end' });
    }
  }, [currentDateRef, isM]);

  return (
    <Row className={styles.selector}>
      {res.map(({ timestamp, hasData }) => {
        const isActive = moment(timestamp).format('YYYY-MM') === moment.unix(Number(period)).format('YYYY-MM');
        const text = moment(timestamp).format(moment(timestamp).year() === moment().year() ? 'MMM' : 'MMM YYYY');
        const action = hasData ? changePeriod : noop;

        return isActive ? (
          <div className={styles.wrapperRef} ref={wrapperRef}>
            <Button
              key={timestamp}
              theme="secondary-dark"
              smallPadding
              small
              ref={currentDateRef}
              className="time-button"
            >
              {text}
            </Button>
          </div>
        ) : (
          <Col
            className={cn(styles.selector__item, { [styles.selector__item_hasData]: hasData })}
            onClick={() => action(timestamp)}
            align="center"
            key={timestamp}
          >
            <Text weight={hasData ? 'bold' : 'thin'} color={hasData ? 'black' : 'gray'}>{text}</Text>
          </Col>
        );
      })}
    </Row>
  );
};

export default PeriodSelector;
