/* eslint-disable react/no-array-index-key */
import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Col, Row } from '../../../../components/grid';
import { formatDataAmount } from '../../../../utils/format';
import { getUsageByPeriod } from '../../../../store/selectors';
import { Text } from '../../../../components/typography';
import { RouteParams } from '../../types';

import styles from './DetailsTable.module.scss';
import { UsageItem } from '../../../../store/reducers/package/types';

const DetailTable = () => {
  const { period } = useParams<RouteParams>();
  const monthPeriod = moment.unix(Number(period)).format('DD MMM YYYY');
  const data: any = useSelector(getUsageByPeriod(monthPeriod));

  const items: UsageItem[] = [];

  data.forEach((el: any) => {
    items.push(...el.details);
  });

  const sortedDates = items.sort((a, b) => {
    return a.timestamp - b.timestamp;
  }).reverse();

  return (
    <Col direction="column" grow noGutter className={styles.table}>
      {sortedDates.map(({
        timestamp, countries, total,
      }, index) => {
        const { unit: downUnit, amount: downAmount } = formatDataAmount(total * 1024 * 1024);
        const date = moment.unix(timestamp).format('DD MMM YYYY');
        const isToday = !moment().diff(moment.unix(timestamp), 'days');
        const color = isToday ? 'blue' : 'black';

        return (
          <Row className={styles.table__row} grow key={index}>
            <Col className={styles.table__date}>
              <Text color={color} title={date} size="m" weight="medium">{date}</Text>
            </Col>
            <Col className={styles.table__country} grow>
              <Text color={isToday ? 'blue' : 'gray'} size="m">{countries.join(', ')}</Text>
            </Col>
            <Col justify="end" className={styles.table__down}>
              <Text color={color} size="m" weight="medium">{`${downAmount} ${downUnit}`}</Text>
            </Col>
          </Row>
        );
      })}
    </Col>
  );
};

export default DetailTable;
