import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from 'react-use';
import { RoutePaths } from '../../../../routes/types';
import Layout from '../../../../components/layout';
import Modal from '../../../../components/modal';
import { useStores } from '../../../../store/mobx/useStore';
import { getDeviceToken } from '../../../../store/selectors';
import Input from '../../../../components/form/input';
import Button from '../../../../components/button';
import { LocalStorageKeys } from '../../../../types';
import { authActions } from '../../../../store/actions';

import styles from '../modal-mobile-layout/ModalMobileLayout.module.scss';
import s from '../email-form/EmailForm.module.scss';

const Verify = () => {
  const history = useHistory();
  const onClose = useCallback(() => history.push('/'), [history]);
  const deviceToken = useSelector(getDeviceToken) as string;
  const { userStore: { sendCodeAsync, sendEmailAsync } } = useStores();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    // eslint-disable-next-line no-use-before-define
    onSubmit: (values) => submit(values.code),
    validateOnChange: false,
  });

  const [email, setEmail] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tokenFromStorage, setStorageToken] = useLocalStorage<string>(LocalStorageKeys.AccessToken);
  const { search } = useLocation();

  useLayoutEffect(() => {
    const query = new URLSearchParams(search);
    const emailStr = query.get('email');
    if (emailStr) {
      setEmail(emailStr);
    }
  }, [search]);

  const {
    handleSubmit, handleChange, errors, setErrors,
  } = formik;

  const submit = async (code: string) => {
    sendCodeAsync(code, deviceToken).then((res) => {
      setStorageToken(res.data.token);
      localStorage.setItem(LocalStorageKeys.AccessToken, res.data.token);
      dispatchEvent(new Event('storage'));
      dispatch(authActions.getMyProfile());
      history.push(RoutePaths.Landing);
    }).catch((err) => {
      const { response } = err;
      if (response) {
        const { data } = response;
        setErrors({ code: data.message });
      } else {
        setErrors({ code: 'Something wrong. Please try again later' });
      }
    }).finally(() => setLoading(false));
  };

  const onChange = (e: unknown) => {
    setErrors({ code: '' });
    handleChange(e);
  };

  const sendNewCode = () => {
    setLoading(true);
    sendEmailAsync(email, deviceToken).then(() => {
      localStorage.setItem(LocalStorageKeys.EsimUserEmail, email);
    }).finally(() => setLoading(false));
  };

  return (
    <Modal isOpen shouldCloseOnOverlayClick onRequestClose={onClose}>
      <div className={styles.modal}>
        <div className={styles.modal__topBg} />
        <Layout
          title="Verify"
          headerProps={{ backLink: RoutePaths.Email, action: () => onClose() }}
          className={styles.modal__layout}
        >
          <div className={s.wrapper}>
            <div className={s.title}>Verification</div>
            <div className={s.info}>
              A verification code has been sent to
              <strong>
                {` ${email}`}
              </strong>
              <br />
              <span style={{ paddingTop: '12px', display: 'flex' }}>
                Please check your inbox and enter the verification code below to verify your email address.

              </span>
            </div>
            <form onSubmit={handleSubmit}>
              <Input
                theme="underline"
                name="code"
                placeholder="Enter the code"
                onChange={onChange}
                error={errors.code}
              />
              <Button
                theme="secondary-dark"
                isLoading={isLoading}
                onClick={() => handleSubmit()}
                className={s.btn}
              >
                Continue
              </Button>
              <div className={styles.barBtn}>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
                <div onClick={sendNewCode}>Resend code</div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
                <div onClick={() => history.push(RoutePaths.Email)}>Change email</div>
              </div>
            </form>
          </div>
        </Layout>
      </div>
    </Modal>
  );
};

export default Verify;
