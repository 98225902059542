import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { useDeviceToken, useOauthKey } from '../hooks/auth';
import { authActions } from '../store/actions';
import useAuthByUrlParams from '../hooks/auth/useAuthByUrlParams';
import { LocalStorageKeys } from '../types';

export const usePrepareData = () => {
  const dispatch = useDispatch();
  useDeviceToken();
  useOauthKey();
  useAuthByUrlParams();

  useEffect(() => {
    if (localStorage.getItem(LocalStorageKeys.AccessToken)) {
      dispatch(authActions.getMyProfile());
    }
  }, [dispatch]);
};
