import debounce from 'lodash/debounce';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import ScreenLoader from '../../components/layout/screen-loader';
import { RoutePaths } from '../../routes/types';

const Oauth = () => {
  const history = useHistory();
  const depuncedPush = useMemo(() => debounce(history.push, 400), [history]);
  useEffect(() => {
    depuncedPush(RoutePaths.My);
  }, [depuncedPush]);

  return <ScreenLoader />;
};

export default Oauth;
