import { useDispatch } from 'react-redux';
import { useState, useCallback } from 'react';
import { useToggle } from 'react-use';
import { unwrapResult } from '@reduxjs/toolkit';

interface UseRequestOptions {
  needThrow?: boolean,
  initialLoadingState?: boolean;
}

const DEFAULT_OPTIONS = { needThrow: false, initialLoadingState: false };

const useRequrest = <T, K = Record<string, string>>(
  action: any, { needThrow, initialLoadingState }: UseRequestOptions = DEFAULT_OPTIONS,
) => {
  const [value, setValue] = useState<undefined | Record<string, string>>();
  const [error, setError] = useState<boolean>();
  const [isLoading, toggleIsLoading] = useToggle(initialLoadingState as boolean);
  const dispatch = useDispatch();
  const sendRequest = useCallback(async (data: T) => {
    setValue(undefined);
    setError(undefined);
    toggleIsLoading(true);
    const res = await dispatch(action(data));
    toggleIsLoading();
    if (res.error) {
      setError(res.payload);

      if (needThrow) {
        throw (res.payload);
      }
      return { error: res.payload };
    }
    const resValue: K = unwrapResult(res) as any;
    setValue(resValue as any);
    return { value: resValue };
  }, [action, dispatch, toggleIsLoading, needThrow]);

  return {
    isLoading, sendRequest, value, error,
  };
};

export default useRequrest;
