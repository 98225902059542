import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../routes/types';

import { packageActions } from '../../store/actions';

export const useLoadData = ({ id }: {id: string}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const loadData = useCallback(async () => {
    const { error, payload }: any = await dispatch(packageActions.getPackage({ id }));

    if (error && payload.status === 404) {
      history.replace(RoutePaths.My);
    }

    setIsLoading(false);
  }, [dispatch, id, history]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return isLoading;
};
