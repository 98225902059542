import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from '../../grid';
import { Text } from '../../typography';
import { RoutePaths } from '../../../routes/types';

import styles from './Footer.module.scss';
import { agent } from '../../../cordova-agent';
import { useMediaContext } from '../../../context/media';

const ITEMS = [
  { link: RoutePaths.Faq, title: 'FAQ' },
  { link: RoutePaths.Contacts, title: 'Contact Us' },
  { link: 'https://yollacalls.com', title: 'Yolla Calls', target: '_blank' },
  { link: RoutePaths.Policy, title: 'Privacy Policy' },
  { link: RoutePaths.TermsOfUse, title: 'Terms of Use' },
];
const Footer = () => {
  const { isM } = useMediaContext();
  if (agent.cordova) return null;
  if (isM) return null;
  return (
    <Container main className={styles.footer}>
      <Row className={styles.links}>
        {ITEMS.map(({ link, title, target }) => {
          return (
            <Col key={title} className={styles.link}>
              {link ? (
                <>
                  {target ? (
                    <a href={link} target={target || '_self'}>
                      {title}
                    </a>
                  ) : (
                    <Link to={link}>
                      {title}
                    </Link>
                  )}
                </>
              ) : <Text>{title}</Text>}
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default Footer;
