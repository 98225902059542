// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageData = require('../../package.json');

enum Environment {
  development = 'development',
  production = 'production'
}

const developmentHosts = ['dev', 'development', 'stage', 'localhost'];

export const getEnvironment = (host: string): Environment.development | Environment.production => {
  const hostValue = developmentHosts.filter((el) => host.includes(el));
  if (hostValue.length !== 0) {
    return Environment.development;
  }
  return Environment.production;
};

export const { version } = packageData;
export const environment = (host: string): Environment.development | Environment.production => {
  return getEnvironment(host);
};
