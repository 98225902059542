import React from 'react';

import Card from '../card';
import DetailTable from './details-table';

const Details = () => {
  return (
    <Card title="Detailed">
      <DetailTable />
    </Card>
  );
};

export default Details;
