import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';

import { RoutePaths } from '../../routes/types';
import { packagesActions } from '../../store/actions';
import { useRequest } from '../../hooks/common';
import { isAuthorized } from '../../store/selectors';

export const useLoadData = () => {
  const { isLoading: isL1, sendRequest: getAllPakages } = useRequest(packagesActions.getAllPakages,
    { initialLoadingState: true });
  const { isLoading: isL2, sendRequest: getSimproducts } = useRequest(
    packagesActions.getSimproducts, { initialLoadingState: true },
  );

  useEffect(() => {
    getAllPakages({});
    getSimproducts({});
  }, [getAllPakages, getSimproducts]);

  return isL1 && isL2;
};

export const useScreenActions = () => {
  const hasAuth = useSelector(isAuthorized);
  const history = useHistory();
  const search = history.location?.search ?? '?';
  const [pack, setPackage] = useState<any>();
  const onContinueClick = useCallback(() => {
    const slug = pack?.slug;
    history.push(`/packages/${slug}${search}`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, pack]);
  const onLaterClick = useCallback(() => {
    history.push(hasAuth ? `${RoutePaths.Purchase}${search}` : RoutePaths.SignUp);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, hasAuth]);
  return {
    pack, setPackage, onContinueClick, onLaterClick,
  };
};
