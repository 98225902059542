/* eslint-disable react/no-array-index-key */
import React from 'react';
import cn from 'classnames';

import styles from './Circles.module.scss';

export interface CircleType {
  color: string;
  x: string;
  y: string;
  size: number;
}

interface CirclesProps {
  items: CircleType[];
  overrideColor?: string;
}

const Circles = ({ items, overrideColor }: CirclesProps) => {
  return (
    <div className={styles.circles}>
      {items.map(({
        size, color, x, y,
      }, index) => (
        <i
          key={index}
          className={cn(styles.circle, styles[`circle_color_${overrideColor || color}`])}
          style={{
            width: size,
            height: size,
            top: y,
            left: x,
          }}
        />
      ))}
    </div>
  );
};

export default Circles;
