import React from 'react';
import cn from 'classnames';

import styles from './Grid.module.scss';

interface ColProps extends React.HTMLAttributes<HTMLDivElement> {
  grow?: boolean;
  className?: string;
  noGutter?: boolean;
  justify?: 'start' | 'center' | 'end';
  align?: 'start' | 'center' | 'end';
  direction?: 'row' | 'column';
}

const Col = ({
  children, grow, className, noGutter, justify, direction, align, ...rest
}: ColProps) => {
  return (
    <div
      {...rest}
      className={cn(styles.col, {
        [styles.col_grow]: grow,
        [styles[`col_justify_${justify}`]]: !!justify,
        [styles[`col_align_${align}`]]: !!align,
        [styles.col_no_gutter]: noGutter,
        [styles[`col_direction_${direction}`]]: direction,
        [className as string]: !!className,
      })}
    >
      {children}
    </div>
  );
};

export default Col;
