import round from 'lodash/round';

const DATA_UNITS = {
  megabytes: 'MB',
  gigabytes: 'GB',
};

export const formatDataUnit = (unit: string) => {
  return (DATA_UNITS)[unit as keyof typeof DATA_UNITS] || unit;
};

export const formatDataAmount = (item: number): { unit: string, amount: number } => {
  const mb = item / 1024 / 1024;
  if (mb < 1000) {
    return { unit: DATA_UNITS.megabytes, amount: round(mb, 3) };
  }

  if (mb % 1000 === 0) {
    return { unit: DATA_UNITS.gigabytes, amount: round(mb / 1000) };
  }

  return { unit: DATA_UNITS.gigabytes, amount: round(mb / 1024, 2) };
};
