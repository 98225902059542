import React from 'react';

import Layout from '../../components/layout';
import { Col, Container, Row } from '../../components/grid';
import Menu from '../../components/menu';
import { ReactComponent as EsimIcon } from '../../assets/icons/eSimIcon.svg';

import Intro from './intro/Intro';
import { useAboutMenu } from './hooks';

import styles from './About.module.scss';

const About = () => {
  const aboutMenu = useAboutMenu();

  return (
    <Layout
      headerProps={{ backLink: '/', action: null }}
      className={styles.screen}
      title="About"
    >
      <Intro />
      <Container main className={styles.content}>
        <Col className={styles.menu} direction="column">
          <div className={styles.logoWrapper}>
            <div className={styles.logoFlexWrapper}>
              <div className={styles.logo}>
                <div className={styles.yolla} />
                <EsimIcon />
                <span className={styles.version}>{`Version ${process.env.REACT_APP_VERSION}`}</span>
              </div>
            </div>
          </div>
          <Row>
            <Menu items={aboutMenu} />
          </Row>
        </Col>
      </Container>
    </Layout>
  );
};

export default About;
