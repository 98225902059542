/* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
import React, { useCallback } from 'react';
import cn from 'classnames';

import { getCurrencySign } from '../../utils/currency';
import { formatDataAmount } from '../../utils/format';
import { CurrencyCode } from '../../types';

import { Col, Row } from '../grid';
import styles from './PlanCard.module.scss';

interface PlanCardProps {
  size: number;
  days: number;
  price: number;
  currency: CurrencyCode;
  selected?: boolean;
  onClick?: VoidFunction;
  className?: string;
  inline?: boolean;
}

const PlanCard = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  size, days, price, currency, selected, onClick, className, inline,
}: PlanCardProps) => {
  const { unit, amount } = formatDataAmount(size);
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <Col
      role="button"
      onClick={handleClick}
      className={cn(styles.card, {
        [styles.card_selected]: !!selected,
        [className as string]: !!className,
        [styles.card_inline]: !!inline,
      })}
    >
      <Row align="center" noGutter className={styles.card__size}>{`${amount} ${unit}`}</Row>
      <Row align="center" noGutter={inline} className={styles.card__days}>{`${days} days`}</Row>
      <Row align="center" noGutter className={styles.card__price}>{`${getCurrencySign()}${price}`}</Row>
    </Col>
  );
};

export default PlanCard;
