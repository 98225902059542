import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCallback, useState } from 'react';

import { RoutePaths } from '../../../routes/types';
import { PlanItem } from '../../../store/reducers/packages';
import { isAuthorized } from '../../../store/selectors';

export const useCardsState = ({ plans }: {plans: PlanItem[]}) => {
  const history = useHistory();
  const hasAuth = useSelector(isAuthorized);
  const search = history.location?.search || '?';
  const [selectedPlanId, setSelectedPlanId] = useState(
    plans.find(({ selected }) => selected)?.id ?? plans[1].id,
  );
  const onCardClick = useCallback((id) => {
    setSelectedPlanId(id);
  }, []);
  const onSelect = useCallback(() => {
    const route = hasAuth ? `${RoutePaths.Purchase}${search}&planId=${selectedPlanId}` : RoutePaths.SignIn;
    history.push(route);
  }, [hasAuth, history, selectedPlanId, search]);

  return { onCardClick, selectedPlanId, onSelect };
};
