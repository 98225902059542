import { PlanItem } from '../store/reducers/packages';
import { formatDataAmount } from './format';

const getPlanCost = (volume: number, price: number) => {
  const { amount } = formatDataAmount(volume);
  return price / amount;
};

export const getMinimalPricePlan = (plans: PlanItem[]) => {
  const minPlanIndex = plans.reduce((currentMinIndex: number, plan, index) => {
    const currentMinPlan = plans[currentMinIndex];
    const currentMinPlanCost = getPlanCost(currentMinPlan?.volume, currentMinPlan?.price);
    const currentPlanCost = getPlanCost(plan?.volume, plan?.price);

    return currentMinPlanCost < currentPlanCost ? currentMinIndex : index;
  }, 0);

  return plans[minPlanIndex];
};
