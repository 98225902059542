import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import api from '../../../api';
import { StoreType } from '../../index';
import {
  getAllPackagesItems, getMyPackagesItems, getSimproductItems, getAccountItems,
} from '../../selectors';

import {
  AllPackagesItem,
  MyPackagesItem,
  SimproductItem,
  AcountItem,
  CreateOrderRequest,
  CreateOrderResponse,
} from './types';

export const getAllPakages = createAsyncThunk('packages/getAllPakages', async () => {
  const { data } = await api.get<null, AxiosResponse<AllPackagesItem[]>>('/v2/packages');
  return data;
}, {
  condition: (_, { getState }) => {
    const items = getAllPackagesItems(getState() as StoreType);
    return !items.length;
  },
});

export const getMyPakages = createAsyncThunk('packages/getMyPakages', async () => {
  const { data } = await api.get('/v2/my/packages');
  return data as MyPackagesItem[];
}, {
  condition: (_, { getState }) => {
    const items = getMyPackagesItems(getState() as StoreType);
    return !items.length;
  },
});

export const getMyAccounts = createAsyncThunk('packages/getMyAccounts', async () => {
  const { data } = await api.get<null, AxiosResponse<AcountItem[]>>('/v1/my/accounts');
  return data;
}, {
  condition: (_, { getState }) => {
    const items = getAccountItems(getState() as StoreType);
    return !items.length;
  },
});

export const getSimproducts = createAsyncThunk('packages/getSimproducts', async () => {
  const { data } = await api.get<null, AxiosResponse<SimproductItem[]>>('/v1/simproducts');
  return data;
}, {
  condition: (_, { getState }) => {
    const items = getSimproductItems(getState() as StoreType);
    return !items.length;
  },
});

export const createOrder = createAsyncThunk('packages/createOrder', async (req: CreateOrderResponse) => {
  const { data } = await api.post<CreateOrderRequest, AxiosResponse<CreateOrderResponse>>('/v1/order', req);
  return data;
});
