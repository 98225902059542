import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

import Text, { TextProps } from '../Text/Text';

import styles from './Link.module.scss';

type DefaultProps = React.PropsWithoutRef<LinkProps> & React.RefAttributes<HTMLAnchorElement>
  & TextProps;

type MyLinkProps = DefaultProps

const Link = ({ children, to, ...rest }: MyLinkProps) => {
  const Component: any = to ? RouterLink : Text;
  return (
    <Text {...rest}>
      <Component className={styles.link} to={to}>
        {children}
      </Component>
    </Text>
  );
};

export default Link;
