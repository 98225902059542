import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';

import { getMyPackagesItems, getSimproductItems } from '../../store/selectors';
import { packagesActions } from '../../store/actions';
import { useRequest } from '../../hooks/common';
import { RoutePaths } from '../../routes/types';
import { LocalStorageKeys } from '../../types';

export const useLoadData = () => {
  const { sendRequest, isLoading } = useRequest(
    packagesActions.getSimproducts,
    { initialLoadingState: true },
  );
  const { sendRequest: requestMyPackages, isLoading: isPackagesLoading } = useRequest(
    packagesActions.getMyPakages,
    { initialLoadingState: true },
  );

  useEffect(() => {
    sendRequest({});
    if (localStorage.getItem(LocalStorageKeys.AccessToken)) {
      requestMyPackages({});
    }
  }, [sendRequest, requestMyPackages]);

  return isLoading && isPackagesLoading;
};

export const INITIAL_FORM_VALUES = { os: null, model: null };

export const useProductForm = () => {
  const [product] = useSelector(getSimproductItems);
  const history = useHistory();
  const myPackages: any[] = useSelector(getMyPackagesItems);
  const [formState, setFormState] = useState(INITIAL_FORM_VALUES);
  const changeValues = useCallback((object: Partial<typeof INITIAL_FORM_VALUES>) => {
    setFormState((state) => ({ ...state, ...object }));
  }, []);
  const onSubmit = useCallback(() => {
    const hasPackages = myPackages.length > 0;
    history.push({
      pathname: hasPackages ? RoutePaths.Purchase : RoutePaths.ChoosePackage,
      search: `?simId=${product.id}`,
    });
  }, [myPackages, history, product]);

  return { changeValues, formState, onSubmit };
};
