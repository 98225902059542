import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useMediaContext } from '../../../context/media';
import { ReactComponent as GearIcon } from '../../../assets/icons/gear.svg';
import { getMyProfile } from '../../../store/selectors';
import { ConnectedAuth } from '../../auth/Auth';

interface AuthActionProps {
  darkBg: boolean;
}

const AuthAction = ({ darkBg }: AuthActionProps) => {
  const { isM } = useMediaContext();
  const profile: any = useSelector(getMyProfile);
  const name = profile?.firstName || profile?.email;

  if (isM && !!name) {
    return (
      <Link to="/settings">
        <GearIcon />
      </Link>
    );
  }

  return <ConnectedAuth white={darkBg} />;
};

export default AuthAction;
