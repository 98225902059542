import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useToggle, useClickAway } from 'react-use';
import { useSelector } from 'react-redux';
import { useMediaContext } from '../../context/media';

import { Col, Row } from '../grid';
import { Text } from '../typography';
import authUserIcon from '../../assets/icons/auth-user.png';
import { RoutePaths } from '../../routes/types';

import { getMyProfile } from '../../store/selectors';
import styles from './Auth.module.scss';

interface AuthProps {
  name?: string;
  avatar?: string;
  white?: boolean;
}

const MENU = [
  { link: RoutePaths.My, text: 'My account' },
  { link: RoutePaths.Settings, text: 'Settings' },
  { link: RoutePaths.Logout, text: 'Log out' },
];

const Auth = ({ white, name, avatar = authUserIcon }: AuthProps) => {
  const ref = useRef(null);
  const [isOpen, toggleOpen] = useToggle(false);
  const { isM } = useMediaContext();

  useClickAway(ref, () => {
    if (isOpen) {
      toggleOpen();
    }
  });

  if (!name) {
    return (
      <Row
        noGutter
        align="center"
        justify="center"
        className={cn(styles.auth__login, { [styles.auth__login_white]: white })}
      >
        <Link to={RoutePaths.SignIn}>
          {(isM)
            ? 'Log in'
            : 'Log in / Sign up'}
        </Link>
      </Row>
    );
  }

  return (
    <div ref={ref} className={cn(styles.auth, { [styles.auth_open]: isOpen })}>
      <Row onClick={toggleOpen} align="center" noGutter className={styles.auth__user}>
        <Col noGutter grow>
          <Text size="m" color="black" className={styles.auth__userName}>
            {`Hello, ${name}`}
          </Text>
        </Col>
        <Col>
          <div className={styles.auth__userAvatar}>
            <img srcSet={`${avatar} 2x`} alt={`${name} avater`} />
          </div>
        </Col>
      </Row>
      <Row
        noGutter
        direction="column"
        justify="end"
        className={cn(styles.auth__menu, { [styles.auth__menu_open]: isOpen })}
      >
        {MENU.map(({ link, text }) => (
          <Link key={link} to={link}>
            <Row noGutter justify="center" className={styles.auth__menuItem}>
              {text}
            </Row>
          </Link>
        ))}
      </Row>
    </div>
  );
};

export default Auth;

export const ConnectedAuth = (props: AuthProps) => {
  const profile = useSelector(getMyProfile);

  return <Auth {...props} name={profile?.firstName || profile?.email} />;
};
