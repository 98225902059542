import {
  useEffect, useCallback, useState, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { packagesActions } from '../../store/actions';
import { getMyPackagesItems, getAllPackagesItems } from '../../store/selectors';
import { CIRCLES_1, CIRCLES_2 } from '../../components/packages-card/consts';
import { formatDataAmount } from '../../utils/format';
import { getMinimalPricePlan } from '../../utils/packages';

export const useLoadData = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const loadData = useCallback(async () => {
    setIsLoading(true);
    await Promise.all([
      dispatch(packagesActions.getAllPakages()),
      dispatch(packagesActions.getMyPakages()),
    ]);
    setIsLoading(false);
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return isLoading;
};

export const useMyPackagesCards = () => {
  const data: any[] = useSelector(getMyPackagesItems);

  return data.map((item, index) => ({
    title: item.name,
    circles: [CIRCLES_1, CIRCLES_2][index % 2],
    ...item,
  }));
};

export const usePackagesCards = () => {
  const packages: any = useSelector(getAllPackagesItems);

  return useMemo(() => {
    return packages.map((pack: any) => {
      const plan = getMinimalPricePlan(pack?.plans);
      const { amount, unit } = formatDataAmount(plan?.volume);
      const cost = plan?.price / amount;

      return {
        id: pack?.id,
        title: pack?.name,
        img: pack?.images,
        count: pack?.countries?.length ?? 1,
        country: pack?.name?.toLowerCase() as any,
        cost,
        slug: pack?.slug,
        unit,
      };
    });
  }, [packages]);
};
