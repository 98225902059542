import React from 'react';

import { Container, Row, Col } from '../../../../components/grid';
import PackagesCard from '../../../../components/packages-card';
import { Title } from '../../../../components/typography';

import { useMyPackagesCards } from '../../hooks';
import styles from './BigCards.module.scss';

const BigCards = () => {
  const cardsData = useMyPackagesCards();

  return (
    <Container className={styles.content}>
      <Row>
        <Col>
          <Title tag="h2" font="secondary" size="l">My packages</Title>
        </Col>
      </Row>
      <Row className={styles.cards}>
        {cardsData.map((item: any) => (
          <Col key={item.id}>
            <PackagesCard small whiteBg {...item} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default BigCards;
