import { useCallback, useState } from 'react';

import { authActions } from '../../../store/actions';
import { useRequest } from '../../../hooks/common';
import { OauthRequest, OauthResponse } from '../../../store/reducers/auth/types/oauth';
import { RoutePaths } from '../../../routes/types';
import { agent } from '../../../cordova-agent';
import { navigatorCountry, navigatorLanguage } from '../../../utils/getLocale';

export const useOauth = () => {
  const [selected, setSelected] = useState<OauthRequest['provider']>();
  const { isLoading, sendRequest } = useRequest<OauthRequest, OauthResponse>(
    authActions.oAuthRequest,
  );
  const clickOauth = useCallback(async (provider: OauthRequest['provider']) => {
    setSelected(provider);
    const { value } = await sendRequest({
      provider,
      redirectUrl: `${agent.cordova ? 'yollaesim://yollaesim' : window.location.origin}${RoutePaths.My}`,
      country: navigatorCountry,
      language: navigatorLanguage,
    });
    // eslint-disable-next-line require-atomic-updates
    const redirectUrl = value?.url as string;
    if (redirectUrl) {
      // eslint-disable-next-line require-atomic-updates
      window.location.href = redirectUrl;
    }
  }, [sendRequest]);

  return { clickOauth, isLoading, provider: selected };
};
