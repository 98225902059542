import React, { useEffect } from 'react';

import { Container } from '../../components/grid';
import Layout from '../../components/layout';

import Intro from './intro';
import { useLoadData } from './hooks';
import { tracker } from '../../utils/analytics';
import Content from './content';

import styles from './Purchase.module.scss';

const Purchase = () => {
  useEffect(() => {
    tracker.logView('purchase');
  }, []);

  const isLoading = useLoadData();

  return (
    <Layout
      headerProps={{ backLink: '/', action: null }}
      className={styles.screen}
      title="Purchase"
      isLoading={isLoading}
    >
      <Container main className={styles.content}>
        <Intro />
        <Content />
      </Container>
    </Layout>
  );
};

export default Purchase;
