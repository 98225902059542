/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  PieChart, Pie, Cell, ResponsiveContainer,
} from 'recharts';

const data = [
  { name: 'Group A', value: 100 },
];

const COLORS = {
  purple: 'url(#purple-gradient)',
  orange: 'url(#orange-gradient)',
  red: '#FF4D00',
};

interface Props {
  whiteBg?: boolean,
  small?: boolean,
  color: 'purple' | 'orange' | 'red',
  volume: number;
  remain: number;
}

const getOffestAngle = ({ volume, remain }: {volume: number, remain: number}) => {
  const percent = Math.floor((remain / volume) * 100);
  const offset = 360 - (3.6 * percent);
  return offset;
};

const CardPieChart = ({
  whiteBg, small, color, volume, remain,
}: Props) => {
  const height = small ? 230 : 270;
  const pieColor = COLORS[color] || '#6C757B';
  const offset = getOffestAngle({ volume, remain });

  return (
    <>
      <svg style={{ opacity: 0, position: 'absolute', zIndex: -10 }}>
        <linearGradient id="purple-gradient" x1="60.25" y1="15" x2="60.25" y2="141.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9440FF" />
          <stop offset="1" stopColor="#405EFF" />
        </linearGradient>
        <linearGradient id="orange-gradient" x1="27" y1="134.927" x2="236" y2="134.927" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8C22" />
          <stop offset="1" stopColor="#FFA722" />
        </linearGradient>
      </svg>
      <ResponsiveContainer height={height} debounce={1}>
        <PieChart height={height}>
          <Pie
            data={data}
            cy={(height - 10) / 2}
            innerRadius={96}
            outerRadius={102}
            paddingAngle={0}
            dataKey="value"
            isAnimationActive={false}
          >
            <Cell fill={whiteBg ? '#EFF4F7' : '#fff'} />
          </Pie>
          <Pie
            data={data}
            cy={(height - 10) / 2}
            innerRadius={85}
            outerRadius={115}
            fill="#8884d8"
            paddingAngle={offset}
            dataKey="value"
            startAngle={450}
            endAngle={90}
          >
            <Cell key="cell" fill={pieColor} />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default CardPieChart;
