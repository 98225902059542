/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import * as thunkActions from './thunks';
import { MyProfileGetResponse } from './types';
import { LocalStorageKeys } from '../../../types';

const AUTH_INITIAL_STATE = {
  isLoading: false,
  profile: {} as MyProfileGetResponse,
  deviceToken: '',
  accessToken: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: AUTH_INITIAL_STATE,
  reducers: {
    setDeviceToken: (state, { payload }: PayloadAction<string>) => {
      state.deviceToken = payload;
    },
    setAccessToken: (state, { payload }: PayloadAction<string>) => {
      state.accessToken = payload;
    },
    logout: (state) => {
      state.accessToken = '';
      state.profile = {} as MyProfileGetResponse;
      Sentry.setUser(null);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunkActions.getMyProfile.pending, (state) => {
      state.isLoading = true;
    }).addCase(thunkActions.getMyProfile.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.profile = payload;
      Sentry.setUser({ email: payload?.email || '' });
    });

    builder.addCase(thunkActions.register.fulfilled, (state, { payload }) => {
      state.accessToken = payload.token;
      localStorage.setItem(LocalStorageKeys.AccessToken, payload.token);
    });

    builder.addCase(thunkActions.logIn.fulfilled, (state, { payload }) => {
      state.accessToken = payload.token;
      localStorage.setItem(LocalStorageKeys.AccessToken, payload.token);
    });
  },
});

export const { reducer } = authSlice;
export const actions = { ...authSlice.actions, ...thunkActions };
export default authSlice;
