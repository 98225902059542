import React from 'react';

import { Col, Container, Row } from '../../../../components/grid';
import { Title } from '../../../../components/typography';
import { useMediaContext } from '../../../../context/media';
import NumberedCard from '../../../../components/numbered-card';
import image from '../../../../assets/images/landing/how-it-works@2x.png';

import { CARDS_DATA } from './const';
import styles from './HowItWorks.module.scss';

const HowItWorks = () => {
  const { isM } = useMediaContext();
  return (
    <Container main className={styles.works}>
      <Row justify="center">
        <Title className={styles.works__title} tag="h2" size="xl">
          How does Yolla eSIM work?
        </Title>
      </Row>
      <Row className={styles.works__inner} align="start" justify="spaceBetween">
        {!isM && (
          <Col noGutter>
            <div className={styles.works__img}>
              <img srcSet={`${image} 2x`} alt="How It works" />
            </div>
          </Col>
        )}
        <Col className={styles.works__content} noGutter>
          <Row justify="end" className={styles.works__cards} wrap>
            {CARDS_DATA.map((item, index) => (
              <Col noGutter className={styles.works__card} key={item.title}>
                <NumberedCard {...item} number={`0${index + 1}`} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default HowItWorks;
