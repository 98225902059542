import React from 'react';
import cn from 'classnames';

import { Colors } from '../../../../consts/colors';

import styles from './Chart.module.scss';

interface CustomLabelProps {
  activeTickName: string;
  onClick: (name: string) => void,
  x?: number,
  y?: number,
  payload?: any,
}

const CustomLabel = ({
  activeTickName, y, x, payload, onClick,
}: CustomLabelProps) => {
  const isActive = payload.value === activeTickName;
  const circleBg = isActive ? 'url(#purple-grad)' : 'transparent';
  const textColor = isActive ? Colors.White : Colors.Gray;

  return (
    <g
      onClick={() => onClick(payload.value)}
      className={cn(styles.tick, { [styles.tick_active]: isActive })}
      transform={`translate(${x},${y})`}
    >
      <circle r="12" fill={circleBg} />
      <defs>
        <linearGradient id="purple-grad" x1="12" y1="12.2182" x2="9.31864e-08" y2="12.2182" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9440FF" />
          <stop offset="1" stopColor="#405EFF" />
        </linearGradient>
      </defs>
      <text
        x={0}
        y={0}
        dx={7}
        dy={5}
        textAnchor="end"
        fill={textColor}
        fontSize={14}
      >
        {payload.value}
      </text>
    </g>
  );
};

export default CustomLabel;
