import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import api from '../../../api';

import { AllPackagesItem } from '../packages';
import { GetUsageRequest, UsageResponse } from './types';

export const getPackage = createAsyncThunk('package/getPackage', async ({ id }: {id: string}, { rejectWithValue }) => {
  try {
    const { data } = await api.get(`/v2/packages/${id}`);
    return data as AllPackagesItem;
  } catch (e: any) {
    return rejectWithValue(e.response);
  }
});

export const getUsage = createAsyncThunk('packages/getUsage', async (req: GetUsageRequest) => {
  const res = await api.get<GetUsageRequest, AxiosResponse<UsageResponse>>('/v1/my/usage', { params: req });
  return res.data;
});
