import React from 'react';
import cn from 'classnames';

import Loader from '../loader';

import { Col, Row } from '../grid';
import styles from './Button.module.scss';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  theme: 'primary' | 'secondary-dark' | 'outline' | 'secondary-light' | 'white' | 'flat-white' | 'accent';
  disabled?: boolean;
  fluid?: boolean;
  smallPadding?: boolean;
  small?: boolean;
  isLoading?: boolean;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  staticIcon?: boolean;
}

const Button = React.forwardRef(({
  children,
  theme,
  className,
  disabled,
  fluid,
  smallPadding,
  small,
  isLoading,
  leftIcon,
  rightIcon,
  staticIcon,
  ...rest
}: ButtonProps, ref: any) => {
  const isDarkTheme = ['primary', 'secondary-dark'].includes(theme);

  return (
    <button
      {...rest}
      ref={ref}
      className={cn(
        styles.btn,
        {
          [styles[`btn__theme_${theme}`]]: theme,
          [styles.btn_disabled]: disabled,
          [styles.btn_fluid]: fluid,
          [styles.btn_small]: small,
          [styles.btn_smallPadding]: smallPadding,
          [styles.btn_loading]: isLoading,
          [className as string]: className,
        },
      )}
      type="button"
      disabled={disabled}
    >
      <Row noGutter justify="center" align="center">
        {leftIcon && (
          <Col
            align="center"
            noGutter={!staticIcon}
            className={cn(
              styles.btn__icon,
              styles.btn__icon_left,
              { [styles.btn__icon_static]: staticIcon },
            )}
          >
            {leftIcon}
          </Col>
        )}
        <Col noGutter={staticIcon && !rightIcon}>
          {children}
        </Col>
        {(isLoading || rightIcon) && (
          <Col
            align="center"
            noGutter
            className={cn(
              styles.btn__icon,
              styles.btn__icon_right,
              { [styles.btn__icon_static]: staticIcon },
            )}
          >
            {isLoading
              ? (
                <Loader
                  className={styles.btn__loader}
                  size="small"
                  color={isDarkTheme ? 'white' : 'blue'}
                />
              )
              : rightIcon}
          </Col>
        )}
      </Row>
    </button>
  );
});

Button.displayName = 'Bytton';

export default Button;
