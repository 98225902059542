import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PayloadAction } from '@reduxjs/toolkit';

import { useLocation } from 'react-router-dom';
import { authActions, packagesActions } from '../../store/actions';
import { getDeviceToken } from '../../store/selectors';
import { tracker } from '../../utils/analytics';
import { OauthExchangeResponse } from '../../store/reducers/auth/types/oauth';
import { LocalStorageKeys } from '../../types';
import { navigatorCountry, navigatorLanguage } from '../../utils/getLocale';

export default () => {
  const deviceToken = useSelector(getDeviceToken);
  const dispatch = useDispatch();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const key = searchParams.get('key');

  const getToken = useCallback(async () => {
    if (!key || !deviceToken) {
      return;
    }

    const res = await dispatch<PayloadAction<OauthExchangeResponse>>(
      authActions.oAuthExchange({
        key, deviceToken, country: navigatorCountry, language: navigatorLanguage,
      }) as any,
    );

    const token = res?.payload?.token;
    if (token) {
      localStorage.setItem(LocalStorageKeys.AccessToken, token);
      dispatch(authActions.getMyProfile());
      dispatch(packagesActions.getMyPakages());
      tracker.logEvent('oauth');
    }
  }, [dispatch, key, deviceToken]);

  useEffect(() => {
    getToken();
  }, [key, getToken]);
};
