import React from 'react';
import { TooltipProps } from 'recharts';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { Text } from '../../../../components/typography';
import { Row } from '../../../../components/grid';
import { RouteParams } from '../../types';
import { formatDataAmount } from '../../../../utils/format';

import styles from './Chart.module.scss';

const CustomTooltip = ({ payload, active }: TooltipProps) => {
  const { period } = useParams<RouteParams>();

  if (!active) {
    return null;
  }

  const data = (payload as any)[0]?.payload;
  const date = `${data?.name} ${moment.unix(Number(period)).format('MMM YYYY')}`;
  const { amount: downValue, unit: downUnit } = formatDataAmount(data?.total * 1024 * 1024);

  return (
    <div className={styles.tooltip}>
      <Row>
        <Text weight="medium">{date}</Text>
      </Row>
      <p><Text color="lightblue">{`Total: ${downValue} ${downUnit}`}</Text></p>
    </div>
  );
};

export default CustomTooltip;
