import React from 'react';

import { Text } from '../../../components/typography';

export const DATA = [
  {
    title: 'What is an eSIM?',
    content: <Text size="m">An eSIM is a SIM card that is embedded in the device. It is a service that provides a smarter and more convenient way to stay connected. eSIMs only work on unlocked eSIM-compatible devices. When using an eSIM, you do not need a physical SIM card: on an eSIM compatible device, subscribers can start connecting by simply scanning a QR Code and entering a Confirmation Code or manually entering the details provided in the eSIM confirmation details.</Text>,
  },
  {
    title: 'What devices support eSIM?',
    content: (
      <>
        <p>
          <Text size="m">
            We are working on supporting more eSIM enabled devices to add to the list - we’re dedicated to ensuring that
            anyone, anywhere can stay connected! This list can change as more eSIM-compatible devices are released on
            the market. As of January 2021, the list of eSIM compatible devices is as follows:
          </Text>
        </p>
        <br />
        <p>
          <Text size="m" weight="medium">iOS</Text>
        </p>
        <ul>
          <li><Text size="m">iPhone 11</Text></li>
          <li><Text size="m">iPhone 11 Pro</Text></li>
          <li><Text size="m">iPhone 11 Pro Max</Text></li>
          <li><Text size="m">iPhone 12</Text></li>
          <li><Text size="m">iPhone 12 Mini</Text></li>
          <li><Text size="m">iPhone 12 Pro</Text></li>
          <li><Text size="m">iPhone 12 Pro Max</Text></li>
          <li><Text size="m">iPhone 13 (dual eSIM)</Text></li>
          <li><Text size="m">iPhone 13 mini (dual eSIM)</Text></li>
          <li><Text size="m">iPhone 13 Pro (dual eSIM)</Text></li>
          <li><Text size="m">iPhone 13 Pro Max (dual eSIM)</Text></li>
          <li><Text size="m">iPhone XS</Text></li>
          <li><Text size="m">iPhone XS Max</Text></li>
          <li><Text size="m">iPhone XR</Text></li>
          <li><Text size="m">iPhone SE (2020)</Text></li>
          <li><Text size="m">iPad Air (4th generation)</Text></li>
          <li><Text size="m">iPad Air (3rd Generation)</Text></li>
          <li><Text size="m">iPad Pro 12.9‑inch (4th generation)</Text></li>
          <li><Text size="m">iPad Pro 12.9‑inch (3rd generation)</Text></li>
          <li><Text size="m">iPad Pro 11‑inch (2nd generation)</Text></li>
          <li><Text size="m">iPad Pro 11‑inch (1st generation)</Text></li>
          <li><Text size="m">iPad (8th generation)</Text></li>
          <li><Text size="m">iPad (7th generation)</Text></li>
          <li><Text size="m">iPad Mini (5th Generation)</Text></li>
        </ul>

        <p>
          <Text size="m">Android</Text>
        </p>
        <ul>

          <li><Text size="m">Samsung Galaxy Fold</Text></li>
          <li><Text size="m">Samsung Galaxy S20</Text></li>
          <li><Text size="m">Samsung Galaxy S20+</Text></li>
          <li><Text size="m">Samsung Galaxy S20 Ultra</Text></li>
          <li><Text size="m">Samsung Galaxy Z Flip</Text></li>
          <li><Text size="m">Samsung Note 20+</Text></li>
          <li><Text size="m">Samsung Galaxy S21</Text></li>
          <li><Text size="m">Samsung Galaxy S21+ 5G</Text></li>
          <li><Text size="m">Samsung Galaxy S21+ Ultra 5G</Text></li>
          <li><Text size="m">Samsung Galaxy Note 20</Text></li>
          <li><Text size="m">Samsung Galaxy Note 20 Ultra 5G</Text></li>
          <li><Text size="m">Samsung Galaxy Z Fold2 5G</Text></li>
          <li><Text size="m">Samsung Galaxy Z Fold3 5G</Text></li>
          <li><Text size="m">Nuu Mobile X5</Text></li>
          <li><Text size="m">Google Pixel 3 & 3 XL</Text></li>
          <li><Text size="m">Google Pixel 3a & 3a XL</Text></li>
          <li><Text size="m">Google Pixel 4 & 4 XL</Text></li>
          <li><Text size="m">Google Pixel 5</Text></li>
          <li><Text size="m">Gemini PDA</Text></li>
          <li><Text size="m">Motorola Razr 2019</Text></li>
          <li><Text size="m">Huawei P40</Text></li>
          <li><Text size="m">Huawei P40 Pro</Text></li>
          <li><Text size="m">Huawei Y7 Pro 2019</Text></li>
          <li><Text size="m">Rakuten Mini</Text></li>
          <li><Text size="m">Oppo Find X3 Pro</Text></li>
          <li><Text size="m">Oppo Reno 5A</Text></li>
        </ul>
      </>
    ),
  },
  {
    title: 'How many eSIMs can I have?',
    content: <Text size="m">Compatible devices allow you to install multiple eSIMs – this means you can have one physical SIM card and multiple eSIM plans. The max amount of eSIMs you can have is dependent on your device and its manufacturer; if unsure, please contact the manufacturer. While only one eSIM can be active at once, it only takes a few seconds to switch between them. It can be managed in your device’s Mobile/Cellular Settings.</Text>,
  },
  {
    title: 'How do I know if my destination is covered?',
    content: <Text size="m">Check the covered countries on the Yolla eSIM site or in the app. Download Yolla eSIM for free on Google Play or the App Store.</Text>,
  },
];
