/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { packageActions } from '../../store/actions';
import { useRequest } from '../../hooks/common';
import { getPackageBySlug } from '../../store/selectors';

import { useLoadData as usePackageData } from '../package/hooks';
import { useLoadData as usePackagesData } from '../packages/hooks';

export const useLoadData = ({ id, period }: {id: string, period: string}) => {
  const { isLoading, sendRequest } = useRequest(packageActions.getUsage);
  const isPackageLoading = usePackageData({ id });
  const isPackagesLoading = usePackagesData();
  const pack = useSelector(getPackageBySlug(id));

  const today = new Date();

  useEffect(() => {
    if (pack?.id) {
      const date = Number((today.valueOf() / 1000).toFixed());
      const dateString = moment.unix(Number(date)).format('MM/DD/YYYY');
      const startOfMonth = moment(dateString).startOf('month').format('YYYY-MM-DD HH:mm');
      const from = moment(startOfMonth).subtract('11', 'month').unix();
      const endOfMonth = moment(dateString).endOf('month').format('YYYY-MM-DD HH:mm');
      const end = moment(endOfMonth).unix();
      sendRequest({ package: pack?.id, from, end });
    }
  }, [sendRequest, pack, period]);

  return isLoading || isPackageLoading || isPackagesLoading;
};
