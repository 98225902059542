import React, { useContext, useLayoutEffect } from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import Layout from '../../components/layout';
import { MediaContext } from '../../context/media';

import MobilePackages from './mobile';
import DesktopPackages from './desktop';
import { useLoadData } from './hooks';
import { RoutePaths } from '../../routes/types';

const Packages = () => {
  const { isM } = useContext(MediaContext);
  const { search } = useLocation();
  const history = useHistory();

  const isLoading = useLoadData();

  useLayoutEffect(() => {
    const params = new URLSearchParams(search);
    const key = params.get('key');
    if (key) {
      history.push({
        pathname: RoutePaths.My,
        search: '',
      });
    }
  }, [history, search]);

  return (
    <Layout
      title="My packages | Yolla eSIM"
      isLoading={isLoading}
    >
      {isM ? <MobilePackages /> : <DesktopPackages />}
    </Layout>
  );
};

export default Packages;
