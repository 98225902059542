import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Container } from '../../components/grid';
import Layout from '../../components/layout';

import PeriodSelector from './period-selector';
import Intro from './intro';
import ChartWidget from './chart-widget';
import Details from './details';
import { RouteParams } from './types';

import styles from './Usage.module.scss';

const Usage = ({ match }: RouteComponentProps<RouteParams>) => {
  const { packageSlug } = match.params;
  return (
    <Layout
      headerProps={{ backLink: '/', action: null }}
      className={styles.screen}
      title="Usage"
      isLoading={false}
    >
      <Container main className={styles.content}>
        <Intro slug={packageSlug} />
        <div />
        <PeriodSelector />
        <ChartWidget />
        <Details />
      </Container>
    </Layout>
  );
};

export default Usage;
