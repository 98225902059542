import React from 'react';

import { Col } from '../../../components/grid';
import { Text } from '../../../components/typography';
import { ReactComponent as QrGrad } from '../../../assets/images/installation/qrgrad.svg';
import qrPhone from '../../../assets/images/installation/qrphone@2x.png';

import styles from './DesktopQr.module.scss';

interface Props {
  qrUrl?: string;
  id?: string;
}

const DesktopQr = ({ qrUrl, id }: Props) => {
  return (
    <div className={styles.qr}>
      <QrGrad className={styles.qr__grad} />
      <img srcSet={`${qrPhone} 2x`} alt="phone" className={styles.qr__phone} />
      <Col direction="column" align="center" className={styles.qr__qrBlock}>
        <Text size="xl" weight="medium" className={styles.qr__title}>eSIM QR code</Text>
        <img className={styles.qr__qrImage} src={qrUrl} alt="QR" />
        <Text size="l" className={styles.qr__number} weight="thin">{id}</Text>
      </Col>
    </div>
  );
};

export default DesktopQr;
