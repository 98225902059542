import React, { useRef } from 'react';

import Intro from './intro';
import Packages from './packages';
import HowItWorks from './how-it-works';
import Download from './download';

const DesktopLanding = () => {
  const storesBtnsContainer = useRef<HTMLDivElement>(null);

  return (
    <>
      <Intro btsContainer={storesBtnsContainer} />
      <Packages />
      <HowItWorks />
      <Download btsContainer={storesBtnsContainer} />
    </>
  );
};

export default DesktopLanding;
