import React from 'react';
import cn from 'classnames';

import { Container, Row } from '../grid';
import Layout from '../layout';
import { Title, Text } from '../typography';

import Intro from './intro';

import styles from './StaticPageLayout.module.scss';

export const TitleRow = ({ children }: React.HTMLAttributes<HTMLDivElement>) => (
  <Row>
    <Title size="m">{children}</Title>
  </Row>
);

export const SubTitleRow = ({ children }: React.HTMLAttributes<HTMLDivElement>) => (
  <Row>
    <Title size="s" tag="h4">{children}</Title>
  </Row>
);

export const TextRow = ({ children }: React.HTMLAttributes<HTMLDivElement>) => (
  <Row>
    <Text size="l" color="gray">
      {children}
    </Text>
  </Row>
);

interface StaticPageLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  htmlTitle?: string;
  isLoading?: boolean;
  noLimitWidth?: boolean;
}

const StaticPageLayout = ({
  children, title, htmlTitle, isLoading, className, noLimitWidth,
}: StaticPageLayoutProps) => {
  return (
    <Layout
      className={cn(styles.screen, className)}
      title={title}
      htmlTitle={htmlTitle}
      isLoading={isLoading}
      headerProps={{ backLink: '/my', action: null }}
    >
      <Intro title={title} />
      <Container main className={cn(styles.content)}>
        <div className={cn(styles.content__wrapper, { [styles['content__wrapper_no-limit']]: noLimitWidth })}>
          {children}
        </div>
      </Container>
    </Layout>
  );
};

export default StaticPageLayout;
