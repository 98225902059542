import React, { useLayoutEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import StaticPageLayout from '../../components/static-page-layout';

import { locale } from '../../utils/getLocale';
import { instance } from '../../api/cmsapi';

import s from '../static/Static.module.scss';

type TState = {
  title: string,
  description: string,
  updatedAt: null | Date,
  metaData?: {
    title: string,
    description: string,
  }
}

const TermsOfUse = () => {
  const [state, setState] = useState<TState>({
    title: '',
    description: '',
    updatedAt: null,
  });

  const history = useHistory();

  useLayoutEffect(() => {
    instance.get(`/terms-of-use?locale=${locale}&populate=*`)
      .then((res) => {
        const { data: { data: { attributes } } } = res;
        const newState: TState = {
          title: attributes.title,
          description: attributes.description,
          updatedAt: attributes.updatedAt,
        };
        if (attributes.metaData) {
          const { title, description } = attributes.metaData;
          newState.metaData = {
            title, description,
          };
        }
        setState(newState);
      }).catch(() => {
        history.push('/');
      });
  }, [history]);

  const metaTitle = state.metaData ? state.metaData.title : state.title;
  const metaDescription = state.metaData ? state.metaData.description : undefined;

  return (
    <StaticPageLayout htmlTitle={metaTitle} title={state.title}>
      {metaDescription && (
        <MetaTags>
          <meta name="description" content={metaDescription} />
        </MetaTags>
      )}
      <div className={s.wrapperContent}>
        <ReactMarkdown>
          {state.description}
        </ReactMarkdown>
        {state.updatedAt && (
          <>
            {moment(state.updatedAt).format('DD MMM YYYY')}
          </>
        )}
      </div>

    </StaticPageLayout>
  );
};

export default TermsOfUse;
