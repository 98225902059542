import moment from 'moment';
import { StoreType } from '../index';

export const getPackage = (s: StoreType) => s.package?.item;
export const getPackagePlans = (s: StoreType) => s.package?.item?.plans ?? [];
export const getPackageCountries = (s: StoreType) => s.package?.item?.countries ?? [];
export const getUsage = (s: StoreType) => s.package.usage ?? [];
// eslint-disable-next-line max-len
export const getUsageByPeriod = (period: string) => (s: StoreType) => {
  const filteredPeriod = moment(period).startOf('month').format('DD MM YYYY');
  const details = s.package.usage ? Object.entries(s.package.usage).filter(([key]) => {
    return moment(key).startOf('month').format('DD MM YYYY') === filteredPeriod;
  }).map(([key, value]: any) => {
    return {
      date: key,
      total: value.total,
      details: value.details,
    };
  }) : [];
  return details ?? [];
};
