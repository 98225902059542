export enum RoutePaths {
  About = '/about',
  Oauth = '/oauth/success',
  Landing = '/',
  SignIn = '/signin',
  SignUp = '/signup',
  Login = '/login',
  Verify = '/verify',
  Email = '/email',
  Forgot = '/forgot',
  Settings = '/settings',
  My = '/my',
  Usage = '/usage/:packageSlug/:period',
  Package = '/packages/:id',
  Logout = '/logout',
  Esim = '/esim',
  Purchase = '/purchase',
  Installation = '/installation/:id',
  NotFound = '/not-found/',
  Faq = '/info/faq',
  Contacts = '/info/contacts',
  TermsOfUse = '/info/tou',
  CardStorageAgreement = '/info/card-storage-agreement',
  Policy = '/info/privacy-policy',
  ChoosePackage = '/choose-package'
}
