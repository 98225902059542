import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import cn from 'classnames';

import Layout from '../../components/layout';
import { Container, Row, Col } from '../../components/grid';
import { getPackage } from '../../store/selectors';
import Intro from './intro';

import Info from './info';
import Cards from './cards';
import CountriesList from './countries-list';
import styles from './Package.module.scss';
import { useLoadData } from './hooks';
import { setMetaDesc } from '../../utils/common';

const Package = ({ match }: RouteComponentProps<{id: string}>) => {
  const { id } = match.params;
  const { name, description, slug } = useSelector(getPackage);

  const isLoading = useLoadData({ id });

  setMetaDesc(description);

  return (
    <Layout
      headerProps={{ backLink: '/', action: null }}
      className={styles.screen}
      title={`${name || ''} data package | Yolla eSIM`}
      darkBg
      isLoading={isLoading}
    >
      <Intro isLoading={isLoading} name={name} slug={slug} />
      <Container main className={styles.content}>
        <Row wrap>
          <Col className={cn(styles.content__col, styles.content__info)} grow>
            <Info description={description} />
            <Cards />
          </Col>
          <Col className={cn(styles.content__col, styles.content__countries)}>
            <CountriesList />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Package;
