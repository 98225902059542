import React from 'react';

import { Title } from '../../../components/typography';
import { Container, Row, Col } from '../../../components/grid';

import MyPackages from './my-packages';
import AllPackages from './all-packages';
import styles from './Desktop.module.scss';

const Desktop = () => {
  return (
    <div className={styles.content}>
      <Container main>
        <Row>
          <Col>
            <Title tag="h1" size="xl" font="secondary">My packages</Title>
          </Col>
        </Row>
        <MyPackages />
        <AllPackages />
      </Container>
    </div>
  );
};

export default Desktop;
