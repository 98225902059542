import moment from 'moment';
import { useSelector } from 'react-redux';

import { getUsage } from '../../../store/selectors';

export const useSelectorItems = () => {
  const usage: any = useSelector(getUsage);
  const maxPeriod = Object.keys(usage).sort((s1, s2) => (moment(s1) < moment(s2) ? 1 : -1))[0];
  const res = Array(12).fill('').map((_, index) => {
    const date = moment(maxPeriod).subtract(index, 'month');
    const key: string = date.format('DD MMM YYYY');
    const items: any = {};
    Object.entries(usage).forEach(([x, value]: any) => {
      if (value.total !== 0) {
        items[x] = value;
      }
    });
    const hasDataValue: string[] = Object.keys(items).map((el) => moment(el).format('MMM YYYY'));
    const newKey = date.format('MMM YYYY');

    return {
      timestamp: key,
      hasData: Boolean(hasDataValue.find((el) => el === newKey)),
    };
  });

  return res.reverse();
};
