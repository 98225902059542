import { useEffect } from 'react';

import { packagesActions } from '../../store/actions';
import { useRequest } from '../../hooks/common';

export const useInstallationData = () => {
  const { sendRequest, isLoading } = useRequest(packagesActions.getMyAccounts, {
    initialLoadingState: true,
  });
  useEffect(() => {
    sendRequest({});
  }, [sendRequest]);

  return { isLoading };
};
