export const BUTTONS = [
  { value: 'ios', label: 'iOS' },
  { value: 'android', label: 'Android' },
];

export const MODELS = [
  { platform: 'ios', value: 'iPhone 11', label: 'iPhone 11' },
  { platform: 'ios', value: 'iPhone 11 Pro', label: 'iPhone 11 Pro' },
  { platform: 'ios', value: 'iPhone 11 Pro Max', label: 'iPhone 11 Pro Max' },
  { platform: 'ios', value: 'iPhone 12 Mini/Pro', label: 'iPhone 12 Mini/Pro' },
  { platform: 'ios', value: 'iPhone XS', label: 'iPhone XS' },
  { platform: 'ios', value: 'iPhone XS Max', label: 'iPhone XS Max' },
  { platform: 'ios', value: 'iPhone XR', label: 'iPhone XR' },
  { platform: 'ios', value: 'iPhone SE (2020', label: 'iPhone SE (2020)' },
  { platform: 'ios', value: 'iPad Air (3+ gen)', label: 'iPad Air (3+ gen)' },
  { platform: 'ios', value: 'iPad Pro (3+ gen)', label: 'iPad Pro (3+ gen)' },
  { platform: 'ios', value: 'iPad Mini (3+ gen)', label: 'iPad Mini (3+ gen)' },

  { platform: 'android', value: 'Samsung Galaxy Fold', label: 'Samsung Galaxy Fold' },
  { platform: 'android', value: 'Samsung Galaxy S20', label: 'Samsung Galaxy S20' },
  { platform: 'android', value: 'Samsung Galaxy S20+', label: 'Samsung Galaxy S20+' },
  { platform: 'android', value: 'Samsung Galaxy S20 Ultra', label: 'Samsung Galaxy S20 Ultra' },
  { platform: 'android', value: 'Samsung Galaxy Z Flip', label: 'Samsung Galaxy Z Flip' },
  { platform: 'android', value: 'Samsung Note 20+', label: 'Samsung Note 20+' },
  { platform: 'android', value: 'Nuu Mobile X5', label: 'Nuu Mobile X5' },
  { platform: 'android', value: 'Google Pixel 3+', label: 'Google Pixel 3+' },
  { platform: 'android', value: 'Gemini PDA', label: 'Gemini PDA' },
  { platform: 'android', value: 'Motorola Razr 2019', label: 'Motorola Razr 2019' },
  { platform: 'android', value: 'Huawei P40 ', label: 'Huawei P40 ' },
  { platform: 'android', value: 'Huawei P40 Pro', label: 'Huawei P40 Pro' },
  { platform: 'android', value: 'Huawei Y7 Pro 2019', label: 'Huawei Y7 Pro 2019' },
];
