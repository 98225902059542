import AdjustConfig from 'com.adjust.sdk/www/adjust_config';
import { RoutePaths } from './routes/types';

function setupStatusBar() {
  window.StatusBar.overlaysWebView(false);
  window.StatusBar.styleBlackOpaque();
}

function setupDeepLinks() {
  console.log('universalLinks.subscribe ', window.universalLinks);
  window.universalLinks.subscribe(null, (eventData) => {
    console.log(`started from deeplink: ${eventData.url}`);
    const url = `#${RoutePaths.My}?${eventData.url.split('?')[1]}`;
    console.log(`navigate to ${url}`);
    window.location.href = url;
  });
}

export const agent = {
  initialize() {
    document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
    document.addEventListener('resume', this.onResume.bind(this), false);
    document.addEventListener('pause', this.onPause.bind(this), false);
    // document.addEventListener('backbutton', this.onBackButton.bind(this), false);
  },

  onDeviceReady() {
    console.log(
      `onDeviceReady, cordova ${
        window.cordova.version
      }, device ${
        window.device && window.device.model}`,
    );
    setupStatusBar();
    setupDeepLinks();

    setTimeout(() => {
      navigator.splashscreen.hide();
    }, 1000);

    if (window.FCM) {
      window.FCM.getAPNSToken().then((token) => {
        console.log(`FCM token: ${token}`);
      });
    }

    if (window.FCMPlugin) {
      window.FCMPlugin.getToken((token) => {
        console.log(`FCM token ${token}`);
      });
    }

    if (window.Adjust) {
      console.log(`setup Adjust ${process.env.REACT_APP_ADJUST_TOKEN}`);
      // eslint-disable-next-line max-len
      const adjustConfig = new AdjustConfig(process.env.REACT_APP_ADJUST_TOKEN, AdjustConfig.EnvironmentProduction);
      window.Adjust.create(adjustConfig);
    }
  },

  // eslint-disable-next-line consistent-return
  onBackButton() {
    console.log(`onBackButton ${window.location.pathname}`);
    if (
      window.location.hash === '/my' || window.location.pathname === '/'
    ) {
      window.navigator.app.exitApp();
    } else {
      window.history.back();
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onPause() {
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onResume() {
  },

  get cordova() {
    return window.cordova || process.env.REACT_APP_CORDOVA === 'true';
  },
};
