import React from 'react';
import cn from 'classnames';

import { Container, Row, Col } from '../../grid';

import styles from './Breads.module.scss';

export interface BreadItem {
  link?: string,
  text: string
}
export interface BreadsProps extends React.HTMLAttributes<HTMLDivElement> {
  items: BreadItem[];
  darkBg?: boolean;
}

const Breads = ({ items, darkBg, className }: BreadsProps) => {
  return (
    <Container
      main
      className={cn(styles.breads, {
        [styles.breads_white]: darkBg,
        [className as string]: !!className,
      })}
    >
      <Row>
        {items.map(({ link, text }) => (
          <Col className={cn(styles.item, { [styles.item_white]: darkBg })} key={text}>
            {link ? <a href={link}>{text}</a> : <span>{text}</span>}
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Breads;
