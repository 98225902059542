import React from 'react';
import cn from 'classnames';

import { Title } from '../../../components/typography';
import Breads from '../../../components/layout/breads';
import { Container } from '../../../components/grid';

import { ReactComponent as EuropeImage } from '../../../assets/images/country-cards/europe.svg';
import { ReactComponent as AsiaImage } from '../../../assets/images/country-cards/asia.svg';
import { ReactComponent as AmericaImage } from '../../../assets/images/country-cards/America.svg';
import { ReactComponent as WorldImage } from '../../../assets/images/country-cards/world.svg';
import { ReactComponent as WavesImage } from '../../../assets/images/waves.svg';

import styles from './Intro.module.scss';

// const BREADS = [
//   { text: 'Main' },
//   { text: 'eSIM' },
//   { text: 'Choose a plan' },
//   { text: 'Choose a package' },
// ];

interface IntroProps {
  isLoading: boolean;
  name: string;
  slug: string;
}

const IMAGES = {
  europe: EuropeImage,
  asia: AsiaImage,
  'north-america': AmericaImage,
  world: WorldImage,
};

const Intro = ({ isLoading, name, slug }: IntroProps) => {
  const Image = IMAGES[slug as keyof typeof IMAGES] || WorldImage;

  return (
    <div className={cn(styles.intro, styles[`intro_${slug}`], 'bg-pan-left')}>
      <Image className={styles.intro__img} />
      <WavesImage className={styles.intro__waves} />
      <Breads className={styles.intro__breads} items={[]} darkBg />
      {!isLoading && (
      <Container main>
        <Title className={styles.intro__title} tag="h1" size="xxl" font="secondary">{name}</Title>
      </Container>
      )}
    </div>
  );
};

export default Intro;
