import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Col, Row } from '../../../components/grid';
import ProductCard from '../../../components/product-card';
import { getSimproductItems } from '../../../store/selectors';
import { Text } from '../../../components/typography';
import Button from '../../../components/button';
import Select from '../../../components/form/select';
import { useMediaContext } from '../../../context/media';

import styles from './Form.module.scss';
import { INITIAL_FORM_VALUES } from '../hooks';
import { BUTTONS, MODELS } from './const';

interface FormProps {
  formState: typeof INITIAL_FORM_VALUES;
  changeValues: (values: Partial<typeof INITIAL_FORM_VALUES>) => void;
  isSubmitDisabled: boolean;
  onSubmit: VoidFunction;
}

const Form = ({
  formState, changeValues, isSubmitDisabled, onSubmit,
}: FormProps) => {
  const [product] = useSelector(getSimproductItems);
  const { isM } = useMediaContext();
  const modelOptions = useMemo(() => MODELS
    .filter(({ platform }) => platform === formState.os), [formState]);

  return (
    <Col className={styles.form} direction="column">
      <Row className={styles.form__card}>
        <ProductCard {...product} />
      </Row>
      <Row direction="column">
        {!isM && (
          <Row>
            <Text size="l" weight="medium">Your device platform:</Text>
          </Row>
        )}
        <Row className={styles.form__btnGroup}>
          {BUTTONS.map(({ value, label }: any) => (
            <Col key={value}>
              <Button
                theme={value === formState.os ? 'outline' : 'flat-white'}
                onClick={() => {
                  changeValues({ os: value, model: null });
                }}
              >
                {label}
              </Button>
            </Col>
          ))}
        </Row>

        {!isM && (
          <Row>
            <Text size="l" weight="medium">Device model:</Text>
          </Row>
        )}
        <Row noGutter className={styles.form__select}>
          <Select
            onChange={(model: any) => {
              changeValues({ model });
            }}
            value={formState.model}
            placeholder="Choose model"
            options={modelOptions}
            className={cn(styles.form__selectControl, {
              [styles.form__selectControl_selected]: !!formState.model,
            })}
          />
        </Row>

        {!isM && (
          <Row className={styles.form__submit}>
            <Button onClick={onSubmit} disabled={isSubmitDisabled} theme="primary">Continue</Button>
          </Row>
        )}
      </Row>
    </Col>
  );
};

export default Form;
