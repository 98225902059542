import React from 'react';

import { Col, Container, Row } from '../../../../components/grid';
import { Title, Text } from '../../../../components/typography';
import { useMediaContext } from '../../../../context/media';
import phone1 from '../../../../assets/images/landing/download_p1@2x.png';
import phone2 from '../../../../assets/images/landing/download_p2@2x.png';
import googlePlayBtn from '../../../../assets/images/gp-btn.png';
import appStoreBtn from '../../../../assets/images/app-store-btn.png';

import styles from './Download.module.scss';

interface Props {
  btsContainer: React.RefObject<HTMLDivElement>
}

const Download = ({ btsContainer }: Props) => {
  const { isM } = useMediaContext();
  return (
    <Container main className={styles.download}>
      <div ref={btsContainer} />
      <Row className={styles.download__content}>
        <Col className={styles.download__info} justify="center" direction="column">
          <Row>
            <Title size="xl" tag="h2">Download Yolla eSIM</Title>
          </Row>
          <Row>
            <Text color="gray" size="xl">to manage and replenish your eSIM cards anytime, anywhere.</Text>
          </Row>
          <Row className={styles.download__buttons}>
            <Col>
              <a
                href="https://app.adjust.com/fjnwyf5?redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.yollaesim&fallback=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.yollaesim"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img srcSet={`${googlePlayBtn} 2x`} alt="Google play button" />
              </a>
            </Col>
            <Col>
              <a
                href="https://app.adjust.com/fjnwyf5?redirect=https%3A%2F%2Fapps.apple.com%2Fapp%2Fid1498898224&fallback=https%3A%2F%2Fapps.apple.com%2Fapp%2Fid1498898224"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img srcSet={`${appStoreBtn} 2x`} alt="App store button" />
              </a>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row align="center" className={styles.image}>
            <img className={styles.image__phone1} srcSet={`${phone1} 2x`} alt="Phone 1" />
            {!isM && (
              <Col noGutter>
                <div className={styles.works__img}>
                  <img className={styles.image__phone2} srcSet={`${phone2} 2x`} alt="Phone 2" />
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Download;
