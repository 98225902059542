import React from 'react';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';

import { customStyles, customTheme } from './styles';

interface SelectProps extends ReactSelectProps {
  placeholder?: string;
}

const Select = ({ ...rest }: SelectProps) => {
  return (
    <ReactSelect
      {...rest}
      theme={customTheme}
      styles={customStyles}
    />
  );
};

export default Select;
