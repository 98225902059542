import { StylesConfig } from 'react-select';
import { ThemeConfig } from 'react-select/src/theme';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const customStyles: StylesConfig = {
  container: (provided: any) => {
    return {
      ...provided,
      minWidth: 250,
      width: '100%',
      fontFamily: 'SF Compact Display',
    };
  },

  control: (provided: any, { menuIsOpen, theme }: any) => {
    return {
      ...provided,
      height: 60,
      borderRadius: 8,
      border: 'none !important',
      outline: 'none !important',
      padding: '0 10px',
      boxShadow: 'none',
      ...(menuIsOpen && ({
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottom: `1px  solid ${theme.colors.neutral10}`,
      })),
    };
  },

  valueContainer: (provided: any) => {
    return {
      ...provided,
    };
  },

  indicatorSeparator: () => {
    return {
      display: 'none',
    };
  },

  placeholder: (provided: any) => ({
    ...provided,
    fontSize: 18,
    fontWeight: 300,
  }),

  menu: (provided: any) => ({
    ...provided,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    border: 'none',
    boxShadow: '0px 18px 30px rgba(177, 188, 196, 0.5)',
    overflow: 'hidden',
  }),

  menuList: (provided: any) => ({
    ...provided,
    padding: 0,
  }),

  option: (provided: any, { theme }: any) => ({
    ...provided,
    borderBottom: `1px solid ${theme.colors.neutral10}`,
  }),
};

export const customTheme: ThemeConfig = (theme) => ({
  ...theme,
  spacing: { baseUnit: 6, controlHeight: 60, menuGutter: 0 },
  borderRadius: 8,
  colors: {
    ...theme.colors,
    primary: '#415FFF',
    primary50: '#DEE9EF',
    primary75: '#EFF4F7',
    neutral10: '#EBF0F3',
  },
});
