import { makeAutoObservable } from 'mobx';
import api from '../../api';
import { LocalStorageKeys } from '../../types';

export class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  sendEmailAsync = (email: string, token: string) => {
    return api.post('/v2/auth/email', {
      email, deviceToken: token,
    });
  }

  sendCodeAsync = (code: string, token: string) => {
    const email = localStorage.getItem(LocalStorageKeys.EsimUserEmail);
    return api.post('/v2/auth/email/verify', {
      email, deviceToken: token, code,
    });
  }
}
