import React, { FC } from 'react';
import moment from 'moment';
import cn from 'classnames';

import { formatDataAmount } from '../../../utils/format';

import styles from './PieContent.module.scss';

interface Props {
  validTo: number;
  volume: number;
  remain: number;
  isExpired: boolean;
}

const PieContent: FC<Props> = ({
  validTo, volume, remain, isExpired,
}) => {
  const { unit: remainUnit, amount: remainAmount } = formatDataAmount(remain);
  const { unit: volumeUnit, amount: volumeAmount } = formatDataAmount(volume);

  return (
    <div className={styles.content}>
      {isExpired ? (
        <div className={styles.expired}>
          <b>Expired</b>
          <br />
          <span>{moment.unix(validTo).format('DD MMM YYYY')}</span>
        </div>
      ) : (
        <div className={styles.info}>
          <div className={cn(styles.remain, { [styles.remain_spent]: !remain })}>
            <b>{remainAmount}</b>
            {` ${remainUnit}`}
          </div>
          <div className={styles.volume}>
            left from
            <b>{` ${volumeAmount} `}</b>
            {`${volumeUnit}`}
          </div>
          <div className={styles.separator} />
          {remain ? (
            <div className={styles.until}>
              <b>
                {moment.unix(validTo).fromNow()}
              </b>
              remaining
            </div>
          ) : <div className={styles.spent}>Spent</div>}
        </div>

      )}
    </div>
  );
};

export default PieContent;
