import React from 'react';
import cn from 'classnames';

import styles from './Title.module.scss';

interface Props {
  tag?: string;
  children: any;
  className?: string;
  font?: 'main' | 'secondary';
  size: 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs'
  thin?: boolean;
}

const Title = ({
  children, tag, className, size = 'l', font = 'main', thin,
}: Props) => {
  const Tag = tag || 'h2' as any;
  return (
    <Tag className={cn(
      styles.title,
      styles[`title_font_${font}`],
      styles[`title_size_${size}`], {
        [className as string]: !!className,
        [styles.title_thin as string]: !!thin,
      },
    )}
    >
      {children}
    </Tag>
  );
};

export default Title;
