import React from 'react';
import cn from 'classnames';

import { Col, Row } from '../grid';
import { Title, Text } from '../typography';
import { SimproductItem } from '../../store/reducers/packages';
import { getCurrencySign } from '../../utils/currency';
import { ReactComponent as SimCardIcon } from '../../assets/icons/sim-card.svg';

import styles from './ProductCard.module.scss';

interface SimproductItemProps extends Omit<SimproductItem, 'description'> {
  description?: string;
  icon?: string;
}

const ProductCard = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  name, subtitle, description, price, currency, icon,
}: SimproductItemProps) => {
  return (
    <Row grow noGutter className={styles.card}>
      <Col grow noGutter direction="column">
        <Row noGutter className={styles.card__head}>
          <Col
            noGutter
            justify="center"
            align="center"
            className={cn(styles.card__sim, { [styles.card__sim_icon]: !icon })}
          >
            {icon
              ? <img className={styles.card__customIcon} src={icon} alt="Sim" />
              : <SimCardIcon className={styles.card__simIcon} />}
          </Col>
          <Col justify="center" direction="column">
            <Title size="m">{name}</Title>
            <Text size="m" color="gray">{subtitle}</Text>
          </Col>
        </Row>
        {description && (
          <Row noGutter className={styles.card__desc}>
            <Text size="m" color="gray">{description}</Text>
          </Row>
        )}
      </Col>
      <Col justify="center" align="center" className={styles.card__price}>
        <Title size="m">
          {`${getCurrencySign()}${price}`}
        </Title>
      </Col>
    </Row>
  );
};

export default ProductCard;
