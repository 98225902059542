import React from 'react';
import cn from 'classnames';

import styles from './Grid.module.scss';

interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  wrap?: boolean;
  className?: string;
  noGutter?: boolean;
  justify?: 'start' | 'center' | 'end' | 'spaceBetween';
  align?: 'start' | 'center' | 'end';
  direction?: 'column' | 'row';
  grow?: boolean;
}

const Row = React.forwardRef(({
  children, wrap, className, noGutter, direction, justify, align, grow, ...rest
}: RowProps, ref: any) => {
  return (
    <div
      {...rest}
      ref={ref}
      className={cn(styles.row, {
        [styles.row_wrap]: wrap,
        [className as string]: !!className,
        [styles['row_no-gutter']]: !!noGutter,
        [styles[`col_direction_${direction}`]]: direction,
        [styles[`col_justify_${justify}`]]: !!justify,
        [styles[`col_align_${align}`]]: !!align,
        [styles.col_grow]: !!grow,
      })}
    >
      {children}
    </div>
  );
});

Row.displayName = 'Row';

export default Row;
