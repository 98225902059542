import capitalize from 'lodash/capitalize';
import React from 'react';
import cn from 'classnames';

import { Title } from '../../../components/typography';
import Breads from '../../../components/layout/breads';
// import { RoutePaths } from 'routes/types';

import styles from './Intro.module.scss';

interface IntroProps {
  slug: string;
}

const Intro = ({ slug }: IntroProps) => {
  return (
    <div className={cn(styles.intro)}>
      <Breads className={styles.intro__breads} items={[]} />
      <Title className={styles.intro__title} tag="h1" size="xxl" font="secondary">
        {`${capitalize(slug)} Usage`}
      </Title>
    </div>
  );
};

export default Intro;
