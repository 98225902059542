import React from 'react';
import { useTitle } from 'react-use';
import cn from 'classnames';

import Header from './header';
import Footer from './footer';
import styles from './Layout.module.scss';
import ScreenLoader from './screen-loader';

export interface LayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  title: string;
  htmlTitle?: string;
  darkBg?: boolean;
  isLoading?: boolean;
  headerProps?: {
    backLink?: string;
    action?: any;
  }
}

const Layout = ({
  children, className, darkBg = false, isLoading, headerProps, title, htmlTitle,
}: LayoutProps) => {
  useTitle(htmlTitle || title);

  return (
    <div className={cn(styles.layout, {
      [className as string]: !!className,
    })}
    >
      <Header darkBg={darkBg} {...headerProps} />
      <main className={styles.main}>
        {isLoading ? <ScreenLoader /> : children}
      </main>
      <Footer />
      <div id="modals" />
    </div>
  );
};

export default Layout;
