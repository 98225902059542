import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Modal from '../../../../components/modal';
import styles from '../modal-desktop-layout/ModalDesktopLayout.module.scss';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as LoginImg } from '../../../../assets/images/login.svg';
import { Col, Row } from '../../../../components/grid';
import Input from '../../../../components/form/input';
import Button from '../../../../components/button';
import { getDeviceToken } from '../../../../store/selectors';
import { useStores } from '../../../../store/mobx/useStore';
import { LocalStorageKeys } from '../../../../types';
import { RoutePaths } from '../../../../routes/types';
import { isEmail, isNotEmptyString } from '../../../../utils/validators';
import { INITIAL_VALUES } from '../email-form/EmailForm';
import s from './EmailModal.module.scss';
import { Title } from '../../../../components/typography';

function validator({ email }: typeof INITIAL_VALUES) {
  const errors = {} as typeof INITIAL_VALUES;
  if (!isNotEmptyString(email)) {
    errors.email = 'Email is required';
  } else if (!isEmail(email)) {
    errors.email = 'Email is invalid';
  }
  return errors;
}

const EmailModal = () => {
  const history = useHistory();
  const onClose = useCallback(() => history.push('/'), [history]);

  const deviceToken = useSelector(getDeviceToken) as string;
  const { userStore: { sendEmailAsync } } = useStores();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    // eslint-disable-next-line no-use-before-define
    onSubmit: (values) => submit(values.email),
    validateOnChange: false,
    validate: validator,
  });

  const {
    handleSubmit, handleChange, errors, setErrors,
  } = formik;

  const submit = (email: string) => {
    sendEmailAsync(email, deviceToken).then(() => {
      localStorage.setItem(LocalStorageKeys.EsimUserEmail, email);
      history.push(`${RoutePaths.Verify}?email=${email}`);
    }).catch((err) => {
      const { response } = err;
      if (response) {
        const { data } = response;
        setErrors({ email: data.message });
      } else {
        setErrors({ email: 'Something wrong. Please try again later' });
      }
    });
  };

  const onChange = (e: unknown) => {
    setErrors({ email: '' });
    handleChange(e);
  };

  return (
    <Modal isOpen shouldCloseOnOverlayClick onRequestClose={onClose}>
      <div className={styles.close}>
        <CloseIcon onClick={onClose} />
      </div>
      <section className={styles.modal}>
        <Row noGutter className={styles.modal__wrapper}>
          <Col className={cn(styles.side, styles.side_left)}>
            <div className={styles.img}>
              <LoginImg />
            </div>
          </Col>
          <Col className={cn(styles.side, styles.side_right)} grow>
            <div className={s.wrapper}>
              <Title className={styles.side__title} size="xl">Email address</Title>
              <div className={s.info}>Please enter your email to continue</div>
              <form onSubmit={handleSubmit}>
                <Input
                  theme="underline"
                  name="email"
                  placeholder="Email address"
                  onChange={onChange}
                  error={errors.email}
                />
                <Button
                  theme="secondary-dark"
                  onClick={() => handleSubmit()}
                  className={s.btn}
                >
                  Continue
                </Button>
              </form>
            </div>

          </Col>
        </Row>
      </section>
    </Modal>
  );
};

export default EmailModal;
