import React from 'react';

import StaticPageLayout from '../../components/static-page-layout';

const NotFound = () => {
  return (
    <StaticPageLayout title="NotFound 404" />
  );
};

export default NotFound;
