import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ScreenLoader from '../../components/layout/screen-loader';
import { authActions, packagesActions } from '../../store/actions';
import { RoutePaths } from '../../routes/types';
import { wait } from '../../utils/common';
import { LocalStorageKeys } from '../../types';

const Logout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const logout = useCallback(async () => {
    await dispatch(authActions.logout());
    dispatch(packagesActions.logout());
    const deviceToken = localStorage.getItem(LocalStorageKeys.DeviceToken);
    localStorage.clear();
    dispatchEvent(new Event('storage'));
    if (deviceToken) localStorage.setItem(LocalStorageKeys.DeviceToken, deviceToken);
    await wait(500);
    history.push(RoutePaths.Landing);
  }, [dispatch, history]);

  useEffect(() => {
    logout();
  }, [logout]);

  return <ScreenLoader />;
};

export default Logout;
