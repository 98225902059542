import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import {
  HashRouter as HashRouters,
  BrowserRouter as BrowserRouters,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import Router from './routes/Routes';
import RouterCordova from './routes/RoutesCordova';
import store from './store';
import { Provider as MediaProvider } from './context/media';
import { agent } from './cordova-agent';
import { version, environment } from './utils/sentryVariables';

import 'normalize.css';
import './scss/index.scss';

Sentry.init({
  release: version,
  environment: environment(window.location.host),
  dsn: 'https://0fa0ba51262d4835a28ad53f83bd2145@sentry.etservice.net/10',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

Modal.setAppElement('#root');

ReactDOM.render(
  <MediaProvider>
    <Provider store={store}>
      {agent.cordova ? (
        <HashRouters>
          <RouterCordova />
        </HashRouters>
      ) : (
        <BrowserRouters>
          <Router />
        </BrowserRouters>
      )}
    </Provider>
  </MediaProvider>,
  document.getElementById('root'),
);

agent.initialize();
