import React from 'react';
import { Link } from 'react-router-dom';

import { Row, Col } from '../../../../components/grid';
import CountryCard from '../../../../components/country-card';

import { usePackagesCards } from '../../hooks';
import styles from './SmallCards.module.scss';

const SmallCards = () => {
  const cardData: any = usePackagesCards();

  return (
    <Row className={styles.cards}>
      {cardData.map((data: any) => (
        <Col className={styles.card} key={data.id}>
          <Link to={`/packages/${data.slug}`}>
            <CountryCard small {...data} />
          </Link>
        </Col>
      ))}
    </Row>
  );
};

export default SmallCards;
