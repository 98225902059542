import React, { useEffect } from 'react';

import { Col, Container, Row } from '../../components/grid';
import Layout from '../../components/layout';
import Button from '../../components/button';
import { tracker } from '../../utils/analytics';

import MobileFixedFooter from '../../components/mobile-fixed-footer';
import Form from './form';
import Intro from './intro';
import Faq from './faq';
import { useLoadData, useProductForm } from './hooks';
import { DATA } from './faq/const';

import styles from './Esim.module.scss';

const Esim = () => {
  useEffect(() => {
    tracker.logView('buy_esim');
  }, []);

  const isLoading = useLoadData();
  const { changeValues, formState, onSubmit } = useProductForm();
  const isSubmitDisabled = !formState.os || !formState.model;

  return (
    <Layout
      headerProps={{ backLink: '/my', action: undefined }}
      className={styles.screen}
      title="Buy eSIM"
      isLoading={isLoading}
    >
      <Intro />
      <Container main className={styles.content}>
        <Row className={styles.content__row}>
          <Col className={styles.content__form}>
            <Form
              isSubmitDisabled={isSubmitDisabled}
              changeValues={changeValues}
              formState={formState}
              onSubmit={onSubmit}
            />
          </Col>
          <Col justify="end" className={styles.content__faq}>
            <Faq data={DATA} />
          </Col>
        </Row>
        <MobileFixedFooter>
          <Button onClick={onSubmit} disabled={isSubmitDisabled} fluid theme="primary">Continue</Button>
        </MobileFixedFooter>
      </Container>
    </Layout>
  );
};

export default Esim;
