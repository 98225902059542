import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Col, Container, Row } from '../../../../components/grid';
import { Title } from '../../../../components/typography';
import { getAllPackagesItems } from '../../../../store/selectors';
import CountryCardWhite from '../../../../components/country-card-white';

import Loader from '../../../../components/loader';
import styles from './Packages.module.scss';

const Packages = () => {
  const packages: any[] = useSelector(getAllPackagesItems);

  return (
    <>
      <Container className={styles.packages} main>
        <Row align="center" direction="column">
          <Row>
            <Title tag="h2" size="xl">eSIM Packages</Title>
          </Row>
        </Row>
      </Container>

      <Row className={styles.packages__cardsWrapper}>
        <Row justify="start" className={styles.packages__cards}>
          {!packages.length && (
            <Col grow justify="center" align="center">
              <Loader color="blue" size="large" />
            </Col>
          )}
          {packages.map((item) => (
            <Col className={styles.packages__cardWrapper} key={item.id}>
              <Link to={`/packages/${item.slug}`}>
                <CountryCardWhite item={item} />
              </Link>
            </Col>
          ))}
        </Row>
      </Row>
    </>
  );
};

export default Packages;
