import React from 'react';
import noop from 'lodash/noop';
import {
  BarChart, Bar, XAxis, ResponsiveContainer, Cell, Tooltip,
} from 'recharts';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { Colors } from '../../../../consts/colors';
import { RouteParams } from '../../types';
import { UsageItem } from '../../../../store/reducers/package/types';
import { isCurrentMonth } from '../../../../utils/time';

import CustomTick from './CustomTick';
import { useChartData } from './hooks';
import CustomTooltip from './CustomTooltip';

export interface ChartProps {
  details: UsageItem[];
}

const Chart = ({ details }: ChartProps) => {
  const { period } = useParams<RouteParams>();
  const activeTickName = isCurrentMonth(period) ? moment().format('DD MMM YYYY') : '';

  const data = useChartData({ details });

  return (
    <ResponsiveContainer
      width="100%"
      minWidth="900px"
      height={200}
    >
      <BarChart
        data={data}
        margin={{ bottom: 20 }}
      >
        <Tooltip
          content={<CustomTooltip />}
          cursor={false}
        />
        <XAxis tick={<CustomTick onClick={noop} activeTickName={activeTickName} />} tickMargin={24} axisLine={false} tickLine={false} dataKey="name" />
        <Bar dataKey="totalValue" barSize={20} radius={5} stackId="a" fill={Colors.Blue} />
        <Bar minPointSize={10} dataKey="empty" radius={5} stackId="a" fill={Colors.LightGray}>
          {
            data.map(({ empty, name }) => {
              const notEmpty = empty === undefined;
              return <Cell key={name} fill={notEmpty ? 'transparent' : Colors.LightGray} />;
            })
          }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
