import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useMediaContext } from '../../../context/media';
import { Col, Row } from '../../../components/grid';
import { Text } from '../../../components/typography';
import { tracker } from '../../../utils/analytics';
import { AcountItem } from '../../../store/reducers/packages';

import DesktopQr from '../desktop-qr';
import MobileShareBlock from '../mobile-share-block';

import styles from './Content.module.scss';

interface Props {
  account?: AcountItem;
  id: string;
}

const Content = ({ account, id }: Props) => {
  useEffect(() => {
    tracker.logView('qrcode');
  }, []);

  const { isTablet, isM } = useMediaContext();
  const qrUrl = account?.qrUrl;

  if (!account) {
    return <Redirect to="/not-found" />;
  }

  return (
    <>
      {isM && <MobileShareBlock id={id} qrUrl={qrUrl} />}
      <Row direction={isTablet ? 'column' : 'row'} className={styles.content}>
        <Col grow className={styles.content__listCol}>
          <ul className={styles.content__list}>
            <li className={styles.content__listItem}>
              <Text size={isM ? undefined : 'l'} weight="medium">1.</Text>
              {' '}
              <Text size={isM ? undefined : 'l'} weight="thin">Go to</Text>
              {' '}
              <Text size={isM ? undefined : 'l'} weight="medium">Mobile Data</Text>
            </li>
            <li className={styles.content__listItem}>
              <Text size={isM ? undefined : 'l'} weight="medium">2.</Text>
              {' '}
              <Text size={isM ? undefined : 'l'} weight="thin">Find</Text>
              {' '}
              <Text size={isM ? undefined : 'l'} weight="medium">Add Cecullar Plan</Text>
            </li>
            <li className={styles.content__listItem}>
              <Text size={isM ? undefined : 'l'} weight="medium">3.</Text>
              {' '}
              <Text size={isM ? undefined : 'l'} weight="thin">Scan QR-Code</Text>
            </li>
            <li className={styles.content__listItem}>
              <Text size={isM ? undefined : 'l'} weight="medium">4.</Text>
              {' '}
              <Text size={isM ? undefined : 'l'} weight="thin">Follow the instructions</Text>
            </li>
          </ul>
        </Col>
        {!isM && (
          <Col className={styles.content__imgCol} grow>
            <DesktopQr id={id} qrUrl={qrUrl} />
          </Col>
        )}
      </Row>
      {isM && (
        <Row>
          <Text color="gray" weight="medium">Important:&nbsp;</Text>
          <Text color="gray">eSIM can be installed only once</Text>
        </Row>
      )}
    </>
  );
};

export default Content;
