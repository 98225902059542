import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import Layout from '../../components/layout';
import { useMediaContext } from '../../context/media';
import AuthModal from './auth-modal';
import { useLandingData } from './hooks';
import DesktopLanding from './desktop-landing';
import { tracker } from '../../utils/analytics';
import { LocalStorageKeys } from '../../types';
import { RoutePaths } from '../../routes/types';
import MobileLanding from './mobile-landing';
import { agent } from '../../cordova-agent';

const Landing = () => {
  const { isM } = useMediaContext();
  const history = useHistory();
  useEffect(() => {
    if (isM && localStorage.getItem(LocalStorageKeys.AccessToken)) {
      history.push(RoutePaths.My);
    }
  }, [isM, history]);

  useLandingData();

  useEffect(() => {
    tracker.logView('welcome');
  }, []);

  return (
    <>
      {agent.cordova ? <MobileLanding /> : (
        <Layout title="Buy eSIM online at local rates. | Yolla eSIM">
          <DesktopLanding />
        </Layout>
      )}
      <AuthModal />
    </>
  );
};

export default Landing;
