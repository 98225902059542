import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Col, Row } from '../grid';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import Circles, { CircleType } from '../circles';
import { MyPackagesItem } from '../../store/reducers/packages';

import { formatDataUnit } from '../../utils/format';
import CardPieChart from './pie-chart';
import PieContent from './pie-content';
import styles from './PackagesCard.module.scss';

interface PackagesCardProps extends MyPackagesItem {
  title: string;
  small?: boolean;
  whiteBg?: boolean;
  circles: CircleType[],
}

const getPackageColor = ({ remain, volume }: { remain: number, volume: number }) => {
  const percent = Math.floor((remain / volume) * 100);
  // eslint-disable-next-line no-nested-ternary
  return percent >= 25 ? 'purple' : percent < 10 ? 'red' : 'orange';
};

const PackagesCard = ({
  title,
  small,
  whiteBg,
  circles,
  lastUsageAt,
  lastUsage,
  unit,
  validTo,
  volume,
  remain,
  id,
  slug,
}: PackagesCardProps) => {
  const isExpired = moment().isAfter(moment.unix(validTo));
  const color = isExpired ? 'gray' : getPackageColor({ remain, volume });

  const lastUsageDate = lastUsageAt || moment(Date.now() / 1000).valueOf();

  return (
    <div className={cn(styles.card, {
      [styles.card_white]: !!whiteBg,
      [styles.card_small]: !!small,
    })}
    >
      <Circles overrideColor={color} items={circles} />
      <div className={styles.card__wrapper}>
        <Row className={styles.header}>
          <Col grow>
            <h3 className={styles.title}>{title}</h3>
          </Col>
          <Col>
            <Link className={styles.infoLink} to={`/packages/${id}`}>
              <InfoIcon className={styles.icon} />
            </Link>
          </Col>
        </Row>
        <Row className={styles.chart}>
          <Col grow>
            <CardPieChart
              volume={volume}
              remain={remain}
              color={color as any}
              small={small}
              whiteBg={whiteBg}
            />
            <Link to={`/usage/${slug}/${lastUsageDate}`}>
              <PieContent
                validTo={validTo}
                volume={volume}
                isExpired={isExpired}
                remain={remain}
              />
            </Link>
          </Col>
        </Row>
        <Row noGutter className={styles.footer}>
          <Col>
            {!!lastUsageAt && (
              <span className={styles.usage}>
                <b>Last usage:</b>
                {` ${moment.unix(lastUsageAt).fromNow()}`}
              </span>
            )}
          </Col>
          <Col>
            {!!lastUsage && (
              <span className={styles.usage}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <b>Today's usage:</b>
                {` ${lastUsage} ${formatDataUnit(unit)}`}
              </span>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PackagesCard;
