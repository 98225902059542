import React from 'react';

import { Row } from '../grid';
import { Title, Text } from '../typography';

import styles from './NumberedCard.module.scss';

interface NumberedCardProps {
  number: string;
  title: string;
  description: string;
}

const NumberedCard = ({ number, title, description }: NumberedCardProps) => {
  return (
    <div className={styles.card}>
      <Row>
        <Title className={styles.card__number} tag="h3" size="m">{number}</Title>
      </Row>
      <Row>
        <Title className={styles.card__title} tag="h4" size="m">{title}</Title>
      </Row>
      <Row>
        <Text className={styles.card__desc} tag="p" size="l">{description}</Text>
      </Row>
    </div>
  );
};

export default NumberedCard;
