/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector } from 'react-redux';

import { getPackageCountries } from '../../../store/selectors';
import { getCountryName } from '../../../utils/countries';

import styles from './CountriesList.module.scss';

const WidgetCard = () => {
  const countries: any[] = useSelector(getPackageCountries);

  const showList = countries.length > 1;

  return (
    <>
      {showList && (
        <div className={styles.countries}>
          <h2 className={styles.countries__title}>
            {countries.length > 1 ? `${countries.length} countries` : 'Country'}
          </h2>
          <div className={styles.countries__list}>
            {countries.map((countryCode: any) => (
              <div className={styles.country} key={countryCode} title={getCountryName(countryCode)}>
                <div className={styles.country__flag}>
                  <img src={`https://static.yollacalls.com/app/flag/${countryCode}.png`} alt="" />
                </div>
                <div className={styles.country__name}>{getCountryName(countryCode)}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>

  );
};

export default WidgetCard;
