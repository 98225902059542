import React from 'react';
import { useSelector } from 'react-redux';

import { Col, Row } from '../../../components/grid';
import { getPackagePlans } from '../../../store/selectors';
import PlanCard from '../../../components/plan-card';
import Button from '../../../components/button';
import { useMediaContext } from '../../../context/media';

import { useCardsState } from './hooks';
import styles from './Cards.module.scss';

const Card = () => {
  const plans: any[] = useSelector(getPackagePlans);
  const { onCardClick, selectedPlanId, onSelect } = useCardsState({ plans });
  const { isM } = useMediaContext();

  return (
    <>
      <Row className={styles.cards}>
        {plans.map(({
          price, currency, id, days, volume,
        }) => (
          <Col key={id}>
            <PlanCard
              onClick={() => onCardClick(id)}
              currency={currency}
              days={days}
              size={volume}
              price={price}
              selected={selectedPlanId === id}
              className={styles.card}
              inline={isM}
            />
          </Col>
        ))}
        {!plans.length && 'No plans'}
      </Row>
      <Row className={styles.cards}>
        <Col noGutter={isM}>
          <Button onClick={onSelect} fluid={isM} theme="primary">
            Continue
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Card;
