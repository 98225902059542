/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from 'react';

import { Row } from '../grid';
import DrawerItem from './drawer-item';

import { DrawerItemProps } from './drawer-item/DrawerItem';

interface DrawerProps {
  items: Pick<DrawerItemProps, 'title' | 'content'>[];
}

const Drawer = ({ items }: DrawerProps) => {
  const [activeItem, setActiveItem] = useState<number | null>();
  const onItemClick = useCallback((index) => {
    setActiveItem((current) => {
      return current === index ? null : index;
    });
  }, []);

  return (
    <Row direction="column">
      {items.map((item, index) => (
        <DrawerItem
          {...item}
          onClick={() => onItemClick(index)}
          key={index}
          isActive={activeItem === index}
        />
      ))}
    </Row>
  );
};

export default Drawer;
