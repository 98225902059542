import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { packagesActions } from '../../store/actions';

export const useLandingData = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(packagesActions.getAllPakages());
  }, [dispatch]);
};
