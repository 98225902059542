import React from 'react';
import cn from 'classnames';

import { Title } from '../../../components/typography';
import Breads from '../../../components/layout/breads';
import { Container } from '../../../components/grid';

import styles from './Intro.module.scss';

// const BREADS = [
//   { text: 'Main' },
//   { text: 'Settings' },
// ];

const Intro = () => {
  return (
    <div className={cn(styles.intro)}>
      <Breads className={styles.intro__breads} items={[]} />
      <Container main>
        <Title className={styles.intro__title} tag="h1" size="xxl" font="secondary">Settings</Title>
      </Container>
    </div>
  );
};

export default Intro;
