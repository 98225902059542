import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../../api';

import {
  RegisterDevicePostResponse,
  RegisterDevicePostRequest,
  RegisterPostRequest,
  RegisterPostResponse,
  SignInRequest,
  SignInResponse,
} from './types';
import {
  OauthExchangeRequest, OauthExchangeResponse, OauthRequest, OauthResponse,
} from './types/oauth';

export const getMyProfile = createAsyncThunk('auth/getMyProfile', async () => {
  const { data } = await api.get('/v1/my/profile');
  return data;
});

export const register = createAsyncThunk('auth/register', async (args: RegisterPostRequest, { rejectWithValue }) => {
  try {
    const { data } = await api.post<RegisterPostResponse>('/v1/register', args);
    return data;
  } catch (e: any) {
    const errors = e.response.data.errors as any;
    return rejectWithValue(errors);
  }
});

export const logIn = createAsyncThunk('auth/logIn', async (args: SignInRequest, { rejectWithValue }) => {
  try {
    const { data } = await api.post<SignInResponse>('/v1/login', args);
    return data;
  } catch (e: any) {
    const errors = e.response.data.errors as any;
    return rejectWithValue(errors);
  }
});

export const forgot = createAsyncThunk('auth/forgot', async (args: SignInRequest, { rejectWithValue }) => {
  try {
    const { data } = await api.post('/v1/forgot', args);
    return data;
  } catch (e: any) {
    const errors = e.response.data.errors as any;
    return rejectWithValue(errors);
  }
});

export const registerDevice = createAsyncThunk<
  RegisterDevicePostResponse,
  RegisterDevicePostRequest
>('auth/registerDevice', async (params: RegisterDevicePostRequest) => {
  const { data } = await api.post<RegisterDevicePostResponse>('/v1/device', params);
  return data;
});

export const oAuthRequest = createAsyncThunk('auth/oAuthRequest', async (params: OauthRequest) => {
  const { data } = await api.post<OauthResponse>('/v1/oauth', params);
  return data;
});

export const oAuthExchange = createAsyncThunk('auth/oAuthExchange', async (params: OauthExchangeRequest) => {
  const { data } = await api.post<OauthExchangeResponse>('/v1/oauth/exchange', params);
  return data;
});
