import React, { useEffect } from 'react';

import { useMediaContext } from '../../../../context/media';

import ModalDesktopWrapper from '../modal-desktop-layout';
import ModalMobileWrapper from '../modal-mobile-layout';
import { ModalTypes } from '../types';
import { tracker } from '../../../../utils/analytics';

const SignUp = () => {
  useEffect(() => {
    tracker.logView('sign_up');
  }, []);

  const { isM } = useMediaContext();
  const Wrapper = isM ? ModalMobileWrapper : ModalDesktopWrapper;

  return (
    <Wrapper type={ModalTypes.SignUp} />
  );
};

export default SignUp;
