import React from 'react';
import capitalize from 'lodash/capitalize';
import cn from 'classnames';

import { Col, Row } from '../../../../components/grid';
import { Text, Title } from '../../../../components/typography';
import { formatDataAmount } from '../../../../utils/format';

import styles from './ChartLegend.module.scss';

interface ChartLegendItemProps {
  volume: number;
  type: 'total';
}

const ChartLegendItem = ({ volume = 0, type }: ChartLegendItemProps) => {
  const { unit, amount } = formatDataAmount(volume);

  return (
    <Col direction="column" className={styles.legendItem}>
      <Row noGutter>
        <i className={cn(styles.legendItem__line, styles[`legendItem__line_${type}`])} />
      </Row>
      <Row noGutter>
        <Title font="secondary" size="m">{`${amount} ${unit}`}</Title>
      </Row>
      <Row noGutter>
        <Text size="m" color="gray">{capitalize(type)}</Text>
      </Row>
    </Col>
  );
};

export default ChartLegendItem;
