import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { useLocalStorage } from 'react-use';
import Modal from '../../../../components/modal';
import styles from '../modal-desktop-layout/ModalDesktopLayout.module.scss';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { Col, Row } from '../../../../components/grid';
import { ReactComponent as LoginImg } from '../../../../assets/images/login.svg';
import { getDeviceToken } from '../../../../store/selectors';
import { useStores } from '../../../../store/mobx/useStore';
import { LocalStorageKeys } from '../../../../types';
import { authActions } from '../../../../store/actions';
import { RoutePaths } from '../../../../routes/types';
import s from '../email-modal/EmailModal.module.scss';
import { Title } from '../../../../components/typography';
import Input from '../../../../components/form/input';
import Button from '../../../../components/button';

const VerifyModal = () => {
  const history = useHistory();
  const onClose = useCallback(() => history.push('/'), [history]);
  const deviceToken = useSelector(getDeviceToken) as string;
  const { userStore: { sendCodeAsync, sendEmailAsync } } = useStores();
  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tokenFromStorage, setStorageToken] = useLocalStorage<string>(LocalStorageKeys.AccessToken);
  const { search } = useLocation();

  useLayoutEffect(() => {
    const query = new URLSearchParams(search);
    const emailStr = query.get('email');
    if (emailStr) {
      setEmail(emailStr);
    }
  }, [search]);

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    // eslint-disable-next-line no-use-before-define
    onSubmit: (values) => submit(values.code),
    validateOnChange: false,
  });

  const {
    handleSubmit, handleChange, errors, setErrors,
  } = formik;

  const submit = async (code: string) => {
    setLoading(true);
    sendCodeAsync(code, deviceToken).then((res) => {
      setStorageToken(res.data.token);
      localStorage.setItem(LocalStorageKeys.AccessToken, res.data.token);
      dispatchEvent(new Event('storage'));
      dispatch(authActions.getMyProfile());
      history.push(RoutePaths.Landing);
    }).catch((err) => {
      const { response } = err;
      if (response) {
        const { data } = response;
        setErrors({ code: data.message });
      } else {
        setErrors({ code: 'Something wrong. Please try again later' });
      }
    }).finally(() => setLoading(false));
  };

  const onChange = (e: unknown) => {
    setErrors({ code: '' });
    handleChange(e);
  };

  const sendNewCode = () => {
    setLoading(true);
    sendEmailAsync(email, deviceToken).then(() => {
      localStorage.setItem(LocalStorageKeys.EsimUserEmail, email);
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Modal isOpen shouldCloseOnOverlayClick onRequestClose={onClose}>
      <div className={styles.close}>
        <CloseIcon onClick={onClose} />
      </div>
      <section className={styles.modal}>
        <Row noGutter className={styles.modal__wrapper}>
          <Col className={cn(styles.side, styles.side_left)}>
            <div className={styles.img}>
              <LoginImg />
            </div>
          </Col>
          <Col className={cn(styles.side, styles.side_right)} grow>
            <div className={s.wrapper}>
              <Title className={styles.side__title} size="xl">Verification</Title>
              <div className={s.info}>
                A verification code has been sent to
                <strong>
                  {` ${email}`}
                </strong>
                <br />
                <span style={{ paddingTop: '12px', display: 'flex', wordBreak: 'break-word' }}>
                  Please check your inbox and enter the verification code below to verify your email address.
                </span>
              </div>
              <form onSubmit={handleSubmit}>
                <Input
                  theme="underline"
                  name="code"
                  placeholder="Enter code"
                  onChange={onChange}
                  error={errors.code}
                />
                <Button
                  theme="secondary-dark"
                  onClick={() => handleSubmit()}
                  isLoading={isLoading}
                  className={s.btn}
                >
                  Continue
                </Button>
                <div className={styles.barBtn}>
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
                  <div onClick={sendNewCode}>Resend code</div>
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
                  <div onClick={() => history.push(RoutePaths.Email)}>Change email</div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </section>
    </Modal>
  );
};

export default VerifyModal;
