import { ReactComponent as AppleIcon } from '../../../assets/icons/social/appleIcon.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/icons/social/googleIcon.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/social/facebookIcon.svg';
import { OauthRequest } from '../../../store/reducers/auth/types/oauth';

export const BUTTONS: {
  Icon: React.ComponentType,
  text: string,
  provider: OauthRequest['provider']
}[] = [
  { Icon: GoogleIcon, text: 'Continue with Google', provider: 'google' },
  { Icon: FacebookIcon, text: 'Continue with Facebook', provider: 'facebook' },
  { Icon: AppleIcon, text: 'Continue with Apple', provider: 'apple' },
];

export const MODAL_DATA = {
  signin: {
    infoTitle: "Haven't an account yet?",
    infoLink: '/signup',
    infoBtnText: 'Sign up',
    title: 'Log in',
  },
  signup: {
    infoTitle: 'Already have an account?',
    infoLink: '/signin',
    infoBtnText: 'Log in',
    title: 'Sign up',
  },
  forgot: {
    infoTitle: "Haven't an account yet?",
    infoLink: '/signup',
    infoBtnText: 'Sign up',
    title: 'Forget password?',
  },
};
