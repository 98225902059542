import React from 'react';

import { Title } from '../../../components/typography';
import { Container } from '../../../components/grid';

import styles from './Intro.module.scss';

const Intro = () => {
  return (
    <div className={styles.intro}>
      <Container main>
        <Title className={styles.intro__title} tag="h1" size="xxl" font="secondary">Buy eSIM</Title>
      </Container>
    </div>
  );
};

export default Intro;
