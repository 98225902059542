import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Modal from '../../../../components/modal';
import { Row, Col, Container } from '../../../../components/grid';
import Button from '../../../../components/button';
import { Title } from '../../../../components/typography';
import { useMediaContext } from '../../../../context/media';
import Layout from '../../../../components/layout';

import { BUTTONS } from '../consts';
import styles from './ModalMobileLayout.module.scss';
import { ModalTypes } from '../types';
import { useOauth } from '../hooks';
import { agent } from '../../../../cordova-agent';
import { ReactComponent as EmailIcon } from '../../../../assets/icons/email.svg';
import { RoutePaths } from '../../../../routes/types';

interface ModalDesktopLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  // eslint-disable-next-line react/no-unused-prop-types
  type: ModalTypes;
}

if (agent.cordova) {
  BUTTONS.shift();
}
const ModalMobileLayout = ({ children }: ModalDesktopLayoutProps) => {
  const { isM } = useMediaContext();
  const { isLoading, clickOauth, provider } = useOauth();
  const history = useHistory();
  const onClose = useCallback(() => history.push('/'), [history]);

  return (
    <Modal isOpen shouldCloseOnOverlayClick onRequestClose={onClose}>
      <div className={styles.modal}>
        <div className={styles.modal__topBg} />
        <Layout
          title="Login"
          headerProps={{ backLink: '/', action: null }}
          className={styles.modal__layout}
        >
          <Container>
            <Row noGutter className={styles.modal__wrapper}>
              <Title className={styles.modal__title} size="xl" tag="h2">Welcome!</Title>
            </Row>
            {children}
            <Row>
              <Col noGutter className={styles.otherAuth}>
                {BUTTONS.map(({ Icon, text, provider: p }) => (
                  <Col key={text}>
                    <Button
                      className={styles.otherAuth__button}
                      leftIcon={<Icon />}
                      smallPadding
                      small={isM}
                      theme="white"
                      onClick={() => clickOauth(p)}
                      isLoading={provider === p && isLoading}
                    >
                      {text}
                    </Button>
                  </Col>
                ))}
                <Col>
                  <Button
                    theme="white"
                    className={styles.otherAuth__button}
                    onClick={() => history.push(RoutePaths.Email)}
                    leftIcon={<EmailIcon />}
                  >
                    Continue with Email
                  </Button>
                </Col>
              </Col>
            </Row>
            <Row>
              <div className={styles.info}>
                <div className={styles.infoText}>
                  New to Yolla? Please choose any option to create an account. To access your Yolla eSIM account, please choose the same way you used to sign up.
                </div>
                <span>By proceeding, you accept our </span>
                <Link to={RoutePaths.TermsOfUse}>Terms Of Use </Link>
                and
                <Link to={RoutePaths.Policy}> Privacy Policy</Link>
              </div>
            </Row>

          </Container>
        </Layout>
      </div>
    </Modal>
  );
};

export default ModalMobileLayout;
