import merge from 'lodash/merge';
import set from 'lodash/set';
import moment from 'moment';

import { UsageResponse, UsageStoreState } from './types';

const addTodayUsageItem = (usage: UsageStoreState): UsageStoreState => {
  const key = moment().format('DD MMM YYYY');
  const currentMonthUsage = usage[key];
  const hasTodayUsage = currentMonthUsage && currentMonthUsage?.details
    ?.some(({ timestamp }) => !moment().diff(moment.unix(timestamp), 'days'));

  if (!hasTodayUsage) {
    set(usage, [key], merge(usage[key] || {}, {
      total: 0,
      details: [
        {
          timestamp: moment().unix(),
          total: 0,
          unit: '',
          countries: [],
        },
      ],
    }));
  }

  return usage;
};

export const mapUsageToStore = (res: UsageResponse): UsageStoreState => {
  const sorted = res.sort((i, j) => (i.timestamp < j.timestamp ? 1 : -1));
  const chunked = sorted.reduce<UsageStoreState>((acc, item) => {
    const key = moment.unix(item.timestamp).format('DD MMM YYYY');
    const hasKey = !!acc[key];

    if (!hasKey) {
      set(acc, [key], {
        total: 0,
        details: [],
      });
    }

    const mappedItem = {
      ...item,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      total: item.uploaded / 1000000 + item.downloaded / 1000000,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    acc[key].total += mappedItem.uploaded / 1000000 + mappedItem.downloaded / 1000000;
    acc[key].details.push(mappedItem);
    return acc;
  }, {});

  return addTodayUsageItem(chunked);
};
