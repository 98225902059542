import React from 'react';
import cn from 'classnames';

import { Row } from '../../grid';

import styles from './Input.module.scss';

type DefaultInputProps = React.InputHTMLAttributes<HTMLInputElement> &
  React.ClassAttributes<HTMLInputElement>

export interface InputProps extends DefaultInputProps {
  error?: string;
  type?: string;
  theme?: 'underline' | 'flat';
  suffix?: JSX.Element;
}

const Input = React.forwardRef(({
  error, type = 'text', theme = 'flat', suffix, ...rest
}: InputProps, ref: any) => {
  return (
    <div className={cn(
      styles.wrapper,
      styles[`wrapper_theme_${theme}`],
      { [styles.wrapper_error]: !!error },
    )}
    >
      <input
        {...rest}
        ref={ref}
        type={type}
        className={cn(styles.input, styles[`input_theme_${theme}`], {
          [styles.input_error]: !!error,
          [styles.input_withSuffix]: !!suffix,
        })}
      />
      <Row noGutter align="center" justify="center" className={styles.input__suffix}>
        {suffix}
      </Row>
      <p className={styles.error}>{error}</p>
    </div>
  );
});

Input.displayName = 'Input';

export default Input;
