import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RoutePaths } from '../../routes/types';
import { useRequest } from '../../hooks/common';

import { packagesActions } from '../../store/actions';
import { CreateOrderRequest, CreateOrderResponse } from '../../store/reducers/packages';
import {
  getPackageByPlanId, getPlanById, getSimproductById,
} from '../../store/selectors';
import { formatDataAmount } from '../../utils/format';
import noPlanIcon from '../../assets/icons/no-plan-icon.png';
import { CURRENCY_SIGNS_MAP } from '../../consts/currency';

import { agent } from '../../cordova-agent';

export const useLoadData = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const loadData = useCallback(async () => {
    setIsLoading(true);
    await Promise.all([
      dispatch(packagesActions.getAllPakages()),
      dispatch(packagesActions.getSimproducts()),
    ]);
    setIsLoading(false);
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return isLoading;
};

export const usePurchaseQuery = () => {
  const { search } = useLocation();
  const getSearchValue = new URLSearchParams(search);
  const simId = getSearchValue.get('simId');
  const planId = getSearchValue.get('planId');

  return { planId, simId };
};

const DEFAULT_PLAN = {
  name: 'No plan selected',
  id: '',
  subtitle: '0 Gb',
  price: 0,
  currency: CURRENCY_SIGNS_MAP.USD,
  icon: noPlanIcon,
};

export const usePurchaseData = () => {
  const { planId, simId } = usePurchaseQuery();
  const sim = useSelector(getSimproductById(simId));

  const plackage: any = useSelector(getPackageByPlanId(planId));
  const planItem: any = useSelector(getPlanById(planId));

  const { unit, amount } = formatDataAmount(planItem?.volume ?? 0);

  const plan = {
    name: plackage?.name ?? DEFAULT_PLAN.name,
    id: (planId as string) ?? DEFAULT_PLAN.id,
    subtitle: planItem ? `${amount} ${unit} for ${planItem?.days} days` : DEFAULT_PLAN.subtitle,
    price: planItem?.price ?? DEFAULT_PLAN.price,
    currency: planItem?.currency ?? DEFAULT_PLAN.currency,
    icon: plackage?.images.small ?? DEFAULT_PLAN.icon,
  };

  return { plan, sim };
};

export const useOrderRequest = ({ simId, planId }: { simId?: string, planId?: string }) => {
  const { sendRequest, isLoading } = useRequest<CreateOrderRequest, CreateOrderResponse>(
    packagesActions.createOrder,
  );
  const onSubmit = useCallback(async () => {
    try {
      const data = await sendRequest({
        account: 0,
        ...(simId && ({ sim: simId })),
        ...(planId && ({ plans: [planId] })),
        redirectUrl: `${agent.cordova ? 'yollaesim://yollaesim' : window.location.origin}${RoutePaths.My}`,
      });

      const redirectUrl = data.value?.url as string;
      console.log(`redirectUrl ${redirectUrl}`);
      if (redirectUrl) {
        // eslint-disable-next-line require-atomic-updates
        window.location.href = redirectUrl;
      }
    } catch (e) {
      console.error(e);
    }
  }, [planId, simId, sendRequest]);

  return { onSubmit, isLoading };
};
