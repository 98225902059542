import { useCallback, useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { PayloadAction } from '@reduxjs/toolkit';

import { LocalStorageKeys } from '../../types';
import { authActions } from '../../store/actions';
import { getDeviceToken } from '../../store/selectors';
import { RegisterDevicePostResponse } from '../../store/reducers/auth/types';

const useDeviceToken = () => {
  const dispatch = useDispatch();
  const tokenFromStore = useSelector(getDeviceToken);
  const localStorageToken = window.localStorage.getItem(LocalStorageKeys.DeviceToken);
  const initialValue = localStorageToken || tokenFromStore;
  const [token, setToken] = useLocalStorage(
    LocalStorageKeys.DeviceToken, initialValue,
  );

  const requestDeviceToken = useCallback(async () => {
    const { payload }: PayloadAction<RegisterDevicePostResponse> = await dispatch(
      authActions.registerDevice({
        deviceId: uuid(),
      }),
    ) as any;
    setToken(payload?.deviceToken);
  }, [dispatch, setToken]);

  useEffect(() => {
    if (!token) {
      requestDeviceToken();
    }
  }, [token, requestDeviceToken]);

  useEffect(() => {
    const needUpdateStoreToken = !!tokenFromStore || tokenFromStore !== token;
    if (needUpdateStoreToken && !!token) {
      dispatch(authActions.setDeviceToken(token));
    }
  }, [tokenFromStore, token, dispatch]);
};

export default useDeviceToken;
