import React, { useContext } from 'react';
import { useMedia } from 'react-use';

import { MOBILE_TRESHOLD, DESKTOP_TRESHOLD, TABLET_TRESHOLD } from '../consts/media';

export const MediaContext = React.createContext({
  isM: false,
  isTablet: false,
  isDesktop: false,
  isLargeDesktop: false,
});

export const Provider = ({ children }: {children: any}) => {
  const isM = useMedia(`(max-width: ${MOBILE_TRESHOLD}px)`);
  const isTablet = useMedia(`(max-width: ${TABLET_TRESHOLD}px)`);
  const isDesktop = useMedia(`(max-width: ${DESKTOP_TRESHOLD}px)`);
  const isLargeDesktop = useMedia(`(min-width: ${DESKTOP_TRESHOLD}px)`);
  const value = {
    isM, isTablet, isDesktop, isLargeDesktop,
  };

  return (
    <MediaContext.Provider value={value}>
      {children}
    </MediaContext.Provider>
  );
};

export const useMediaContext = () => {
  const state = useContext(MediaContext);
  return state;
};
