import React from 'react';
import { Route } from 'react-router-dom';

import SignUp from './sign-up';
import Verify from './verify';
import VerifyModal from './verify-modal';
import EmailForm from './email-form';
import EmailModal from './email-modal';
import { RoutePaths } from '../../../routes/types';
import { useMediaContext } from '../../../context/media';

const AuthModal = () => {
  const { isM } = useMediaContext();
  const EmailComponent = isM ? EmailForm : EmailModal;
  const VerifyComponent = isM ? Verify : VerifyModal;
  return (
    <>
      <Route path={[RoutePaths.SignIn, RoutePaths.SignUp]}>
        <SignUp />
      </Route>
      <Route path={RoutePaths.Email}>
        <EmailComponent />
      </Route>
      <Route path={RoutePaths.Verify}>
        <VerifyComponent />
      </Route>
    </>
  );
};

export default AuthModal;
