import React, { useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import MetaTags from 'react-meta-tags';
import StaticPageLayout from '../../components/static-page-layout';
import FaqBlock from '../esim/faq';
import { locale } from '../../utils/getLocale';
import { instance } from '../../api/cmsapi';

import s from '../static/Static.module.scss';

type TState = {
  title: string,
  data: { title: string, content: any }[],
  updatedAt: null | Date,
  metaData?: {
    title: string,
    description: string,
  }
}

const Faq = () => {
  const [state, setState] = useState<TState>({
    title: '',
    data: [],
    updatedAt: null,
  });

  const history = useHistory();

  useLayoutEffect(() => {
    instance.get(`/faq?locale=${locale}&populate=*`)
      .then((res) => {
        const { data: { data: { attributes } } } = res;
        const array = attributes.description.map((el: { title: string, description: string }) => ({
          title: el.title,
          content:
  <div className={s.faqCollapse}>
    <ReactMarkdown>{el.description}</ReactMarkdown>
  </div>,
        }));
        const newState: TState = {
          title: attributes.title,
          data: array,
          updatedAt: attributes.updatedAt,
        };
        if (attributes.metaData) {
          const { title, description } = attributes.metaData;
          newState.metaData = {
            title, description,
          };
        }
        setState(newState);
      }).catch(() => {
        history.push('/');
      });
  }, [history]);

  const metaTitle = state.metaData ? state.metaData.title : state.title;
  const metaDescription = state.metaData ? state.metaData.description : undefined;

  return (
    <StaticPageLayout htmlTitle={metaTitle} title={state.title}>
      {metaDescription && (
        <MetaTags>
          <meta name="description" content={metaDescription} />
        </MetaTags>
      )}
      <FaqBlock data={state.data} />
    </StaticPageLayout>
  );
};

export default Faq;
