/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import * as thunks from './thunks';
import {
  AcountItem,
  AllPackagesItem,
  MyPackagesItem,
  SimproductItem,
} from './types';

const packagesSlice = createSlice({
  name: 'packages',
  initialState: {
    isLoading: true,
    all: [] as AllPackagesItem[],
    my: [] as MyPackagesItem[],
    simproducts: [] as SimproductItem[],
    accounts: [] as AcountItem[],
  },
  reducers: {
    logout(state) {
      state.my = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.getAllPakages.pending, (state) => {
      state.isLoading = true;
    }).addCase(thunks.getAllPakages.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.all = payload;
    });

    builder.addCase(thunks.getMyPakages.pending, (state) => {
      state.isLoading = true;
    }).addCase(thunks.getMyPakages.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.my = payload;
    });

    builder.addCase(thunks.getSimproducts.pending, (state) => {
      state.isLoading = true;
    }).addCase(thunks.getSimproducts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.simproducts = payload;
    });

    builder.addCase(thunks.getMyAccounts.pending, (state) => {
      state.isLoading = true;
    }).addCase(thunks.getMyAccounts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.accounts = payload;
    });
  },
});

export const { reducer } = packagesSlice;
export const actions = {
  ...packagesSlice.actions, ...thunks,
};
export default packagesSlice;
